import React, { Component } from 'react'
import { default as withRouter } from "../../components/WithRouter/withRouter";

import Image from 'react-bootstrap/Image'

class WhatIsReciprocity extends Component {
  render() {
    return (
      <div className="smallContainer">
        <section className="marginBottom100">
          <div className="text-center marginBottom25">
            <Image src="/reciprocity-icon.png" rounded />
          </div>
          <h1 className="text-center mb-5">
            rec<span className="reciprocityLightBlueText">·</span>i<span className="reciprocityLightBlueText">·</span>proc<span className="reciprocityLightBlueText">·</span>i<span className="reciprocityLightBlueText">·</span>ty <span className="reciprocityGreenText">|</span> noun
          </h1>
          <p className='mb-5'>
            <b style={{ fontSize: '1.2rem' }}>
              1. the practice of building relationships through exchanging things of value over time for mutual benefit; latin root: moving back and forth.
            </b>
          </p>
          <div className='font-thin'>
            <p className="text-muted ">
              Reciprocity is about making recognition of Indigenous land rights more meaningful.
            </p>
            <p className="text-muted">
              Reciprocity facilitates connection between home and business owners, renters and event planners with the Indigenous Nations whose lands we live, work, and play on.
            </p>
            <p className="text-muted">
              We want Indigenous Nations to see tangible proof that many land owners, businesses, and those living and working in their territories choose to recognize Indigenous lands and Indigenous rights. Making payments through Reciprocity Trusts provides an opportunity for property and business owners, renters and event planners to start saying thank you by returning wealth, a little every year, and creating a direct and ongoing Indigenous-owned revenue stream that is independent of federal or provincial governments, and increases over time as land values increase.
            </p>
          </div>
        </section>
      </div>
    )
  }
}
export default withRouter(WhatIsReciprocity)

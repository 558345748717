import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RegistrationTypes } from "../../pages/Registration/Registration";

function NavButton(props) {
  const navigate = useNavigate();

  function handleClick(path) {
    navigate(path);
  }

  return (
    <Button
      className={props.type === props.current ? "disabledBtn" : props.class}
      variant="primary"
      disabled={props.type === props.current ? true : false}
      onClick={() => handleClick(props.path)}
    >
      {props.title}
    </Button>
  );
}

class PaymentSelectButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
    };
  }

  nextPath(path) {
    console.log("path:", path);
    console.log("props:", this.props);
    this.props.navigation?.navigate(path);
  }

  render() {
    return (
      <Row className="justify-content-md-center">
        <Col md={12} lg className="text-center">
          <NavButton
            type={RegistrationTypes.homeowner}
            current={this.props.type}
            path="/im-a-homeowner"
            title="I'M A HOMEOWNER"
            class="customBtnBlue"
          />
        </Col>
        <Col md={12} lg className="text-center mt-3 mt-lg-0">
          <NavButton
            type={RegistrationTypes.renter}
            current={this.props.type}
            path="/im-a-renter"
            title="I'M A RENTER"
            class="customBtnRenter"
          />
        </Col>
        <Col md={12} lg className="text-center mt-3 mt-lg-0">
        <NavButton
            type={RegistrationTypes.business}
            current={this.props.type}
            path="/im-a-business-owner"
            title="I'M A BUSINESS OWNER"
            class="customBtnBusiness"
          />
        </Col>
        <Col md={12} lg className="text-center mt-3 mt-lg-0">
        <NavButton
            type={RegistrationTypes.other}
            current={this.props.type}
            path="/im-something-else"
            title="I'M HOSTING AN EVENT"
            class="customBtnElse"
          />
        </Col>
        <Col md={12} lg className="text-center mt-3 mt-lg-0">
        <NavButton
            type={RegistrationTypes.indigenous}
            current={this.props.type}
            path="/im-indigenous"
            title="I'M INDIGENOUS"
            class="customBtnIndigenous"
          />
        </Col>
      </Row>
    );
  }
}

export default PaymentSelectButton;

import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactMap from './ContactMap'

class GetInTouch extends Component {
  render() {
    return (
      <Container className='marginBottom100'>
        <div className="smallContainer">
          <section className="marginBottom100">
            <h1 className="text-center mb-5">
              <span className="reciprocityGreenText">g</span>et in touch<span className="reciprocityLightBlueText">.</span>
            </h1>
          </section>
        </div>
        <Row>
          <Col>
            <p>
              Whether you’re a home owner, a renter, a new or established business, an Indigenous Nation, or anyone else – we want to hear from you. Tell us what you think about this Reciprocity idea we’ve got! We're sure you have ideas and questions...
            </p>
            <p>
              Get in touch by email, phone, or drop by our main office.
            </p>
            <address>
              <p className="mb-0"><strong>Main Office at KWENCH</strong></p>
              <p className="mb-0">2031 Store St</p>
              <p className="mb-0">Victoria BC V8T 5L9</p>
            </address>
            <p className="mb-0"><strong>E: </strong><a href="mailto: hello@reciprocityconnects.ca">hello@reciprocityconnects.ca</a></p>
            <p><strong>P: </strong>(778) 606-2511 or toll free at (800) 789-2839</p>
          </Col>
          <Col md={12} lg={6}>
            <ContactMap />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default GetInTouch

import React from 'react';

import { Container, Card, Row, Col, FormCheck, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Registration, { RegistrationTypes } from '../Registration/Registration';

import RegionSummary from '../../components/RegionSummary/RegionSummary';
import PaymentSelectButton from '../../components/PaymentSelectButton/PaymentSelectButton';
import CurrencyText from '../../components/CurrencyText/CurrencyText';
import CreditCardForm from '../../components/CreditCardForm/CreditCardForm';
import Contact from '../../components/Contact/Contact';
import Success from '../Success/Success';

import { getRegionFromMunicipality, postPledge, postPayment } from '../../utils';

import moment from 'moment';

import styles from './ImARenter.module.css'
import WhatsNextPopover from '../../components/WhatsNextPopover/WhatsNextPopover';

const today = moment();
const currentYear = today.year();

class ImARenter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "register",

      registration: null,

      region: null,

      term: "monthly",
      monthlyPayment: 15,
      address: '',
      publicMap: true,

      pledgeOnly: true,

      description: 'Monthly subscription payment.',

      total: 0,
      currency: 'cad',

      creditCardPayment: null,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.mode !== prevState.mode) {
      window.scrollTo(0, 0);
    }
  }

  calculatePayments = async form => {
    // Note: form.municipality is an object
    // The municipality will give us the region
    let region = await getRegionFromMunicipality(form.municipality);
    
    // hardcoded: If CRD
    if (form.municipality.value === '-2'){
      region = await getRegionFromMunicipality({
        regionId: 'rgn_5',
      })
    }

    let isPledgeOnly = true;
    if (region && region.id === 'rgn_5') {
      isPledgeOnly = false;
    }

    // outside of any region
    if (!region) {
      region = {
        id: null,
        key: 'rft'
      }
    }

    // Calculate payment based on municipal rates
    const payment = form.rent / 100;

    this.setState({
      mode: "calculate",
      registration: form,
      pledgeOnly: isPledgeOnly,
      address: form.address,
      region: region,
      monthlyPayment: Math.ceil(payment),
    }, () => {
      this.calculateTotals();
    });
  }

  calculateTotals = () => {
    this.setState({ total: this.state.monthlyPayment });
  }

  handlePublicMapChange = () => this.setState({ publicMap: !this.state.publicMap });

  handleCreditCardSubmit = async creditCardPayment => {
    this.setState({
      creditCardPayment: creditCardPayment
    }, async () => {
      const { mode, ...payment } = this.state;
      payment['name'] = creditCardPayment?.name || '';
      payment['postalCode']= creditCardPayment?.postalCode || '';
      payment['phone']= creditCardPayment?.phone || '';
      console.log("payment done");
      console.log(creditCardPayment);
      if (!creditCardPayment?.pledgeOnly) {
        // In prod, creditCardPayment.paymentIntent.status === 'requires_payment_method'
        // TODO FIX
        // if (creditCardPayment?.status === 'succeeded') {
        try {
          console.log("Posting payment");
          console.log(payment);
          await postPayment(payment);
          this.setState({ mode: "success" });
        } catch (error) {
          console.log("Posting payment error:");
          console.log(error);
          this.setState({
            mode: "error",
            error: error.message
          });
        }
        // } else {
        //   console.log("Payment wasn't successful.");
        //   console.log(creditCardPayment);
        //   this.setState({
        //     mode: "error",
        //     error: `Payment status: ${creditCardPayment.paymentIntent?.status}`
        //   });
        // }
      }
      try {
        await postPledge(payment);
        this.setState({ mode: "success" });
      } catch (error) {
        this.setState({
          mode: "error",
          error: `Pledge status: ${error.message}`
        });
      }
    });
  }

  subtext() {
    return (
      <>
        <p>
          If you live in B.C., type in your address to see your recommended monthly Reciprocity Payment. It's about 1% of your rent.
        </p>
        <p>
          We know that renting these days is tough.  If the recommended Reciprocity Payment is out of reach for you at this time, enter $0 below (or another amount that lands you in a place where you are comfortable). We will keep you looped into our work, opportunities to stay engaged and connect, and ensure your contributions are recognized and acknowledged on the <a href='/reciprocity-map' target='_blank'>Reciprocity Map</a>.
        </p>

      </>
    )
  }

  render() {
    const { mode, region, monthlyPayment, address, total } = { ...this.state };

    return (
      <div>
        {mode !== 'success' &&
          <h1 className={`marginTop100 marginBottom25 text-center`}>
            <span className="reciprocityGreenText">i</span>'m a renter<span className="reciprocityLightBlueText">. </span>
          </h1>
        }
        {mode === 'register' &&
          <Registration type={RegistrationTypes.renter} image="/reciprocity-homeowner-logo.png" submitText="Calculate my reciprocity payment"
            subText={this.subtext()}
            onSuccess={this.calculatePayments}
          />
        }
        {mode === 'success' &&
          <Success type='renter' pledgeOnly={this.state.pledgeOnly}/>
        }
        {mode === 'error' &&
          <Alert variant='danger'>{this.state.error}</Alert>
        }
        {mode === 'calculate' &&
          <Container>
            <Row>
              <RegionSummary region={region} />
            </Row>
            <Row>
              {region.id === 'rgn_5' ?
                <p>
                  <strong>We’ve calculated your recommended Reciprocity Payment for {currentYear} at a rate of 1% of your monthly rent.</strong>
                </p>
                :
                <p>
                  <strong>We've calculated your recommended Reciprocity Payment but we're not set up to take payments in your area yet. Sign up below and we'll get in touch once we're ready to go.</strong>
                </p>
              }
            </Row>
            <Row>
              <Col xs={0} sm={1} lg={3}></Col>
              <Col xs={12} sm={10} lg={6}>
                <Row className={styles.buttonRow}>
                  <Col>
                    <PaymentSelectButton isSelected type="monthly" address={address} paymentValue={monthlyPayment} year={currentYear}
                      setActivePaymentType={() => { }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Card className={styles.totalCard}>
                    <Card.Header>Total Payments Today:</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xs={8}>
                          Subscription Payment<br />
                          Starting today, {today.format('MMM. D, yyyy')}<br />
                          Billed each month on the {today.format('Do')}
                        </Col>
                        <Col xs={4} className='text-right'>
                          <CurrencyText value={monthlyPayment} />/month
                        </Col>
                      </Row>
                      <hr className={`${styles.hr} my-1`} />
                      <Row className={styles.totalRow}>
                        <Col xs={8}>
                          Total
                        </Col>
                        <Col xs={4} className='text-right'>
                          <CurrencyText value={total} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
                <FormCheck className={styles.publicMap} type='checkbox' id='publicMap' checked={this.state.publicMap}
                  label={
                    <div>
                      <span>I'd like to be recognized on the </span>
                      <Link to={'/reciprocity-map'} target='_blank'>Reciprocity Map.</Link>
                      <span> Please note: your name or address will never be shown on the map.</span>
                    </div>
                  }
                  onChange={this.handlePublicMapChange}
                />
                <div className='d-flex justify-content-center'>
                  <WhatsNextPopover />
                </div>
                <CreditCardForm className={styles.creditCardForm} order={this.state} submit={this.handleCreditCardSubmit} />
                <div className='text-justify'>
                  <small><i>Reciprocity respects your privacy. By clicking {this.state.pledgeOnly ? "'PLEDGE NOW'" : "'MAKE MY RECIPROCITY PAYMENT'"} you consent to share your information with Reciprocity and receive updates, including when we are up and running in your area, by email, text and/or phone. You can unsubscribe at any time.</i></small>
                </div>
                <Contact type='problem' className='text-center pt-4 pb-4' />
              </Col>
              <Col xs={0} sm={1} lg={3}></Col>
            </Row>
          </Container>
        }
      </div>
    )
  }
}

export default ImARenter;

import config from '../config';
import { getSession } from './helpers';


// ******************************************************************************************
// NOT USED (DASHBOARD or dead code)
// ******************************************************************************************
export const getUser = async () => await requestAuthenticatedApi('/user', 'POST');
export const getUsers = async () => await requestAuthenticatedApi('/users', 'POST');
export const getMessages = async () => await requestAuthenticatedApi('/messages', 'GET');
export const getUserMessages = async () => await requestAuthenticatedApi('/user/messages', 'GET');
export const getNewsletters = async () => await requestAuthenticatedApi('/newsletters', 'GET');
export const getUserNewsletter = async () => await requestAuthenticatedApi('/user/newsletter', 'GET');
export const postProperty = async property => await requestApi('/property', 'POST', property);
export const getProperties = async () => await requestAuthenticatedApi('/properties', 'GET');
export const getUserProperties = async () => await requestAuthenticatedApi('/user/properties', 'GET');
export const getPayments = async () => await requestAuthenticatedApi('/payments', 'GET');
export const getUserPayments = async () => await requestAuthenticatedApi('/user/payments', 'GET');
// Registrations ??
export const postRegistration = async form => await requestApi('/registration', 'POST', form);
// NOT USED END (DASHBOARD)


// ******************************************************************************************
//                      USED INTERNAL SERVICE CALLS
// ******************************************************************************************
// Users
export const userRegister = async (email, password) => await requestApi('/user/register', 'POST', { email, password });
export const userLogin = async (email, password) => await requestApi('/user/login', 'POST', { email, password });
export const getUserFromToken = async token => await requestApi('/user', 'GET', null, { Authorization: `Bearer ${token}` });

// Municipalities
export const getMunicipalities = async () => await requestApi('/municipalities', 'GET');

// Regions
export const getRegions = async () => await requestApi('/regions', 'GET');
export const getRuralById = async (id) => await requestApi(`/rural?ruralId=${id}`, 'GET');

export const sendPing = async () => await requestApi('/ping', 'POST');

// Markers
export const getMarkers = async () => await requestApi('/markers', 'GET');

// Mail
export const postMessage = async form => await requestApi('/messages', 'POST', form);

// Newsletter
export const postNewsletter = async form => await requestApi('/newsletter', 'POST', form);

// Orders (Payment Intents)
export const postOrder = async order => await requestAuthenticatedApi('/order', 'POST', order);
export const updateOrder = async (id, properties) => await requestAuthenticatedApi('/order/update', 'POST', { id, properties });

// Payments
export const postPayment = async paymentData => await requestAuthenticatedApi('/payment', 'POST', paymentData);
// Pledges
export const postPledge = async paymentData => await requestApi('/pledge', 'POST', paymentData);
export const postPledge2 = async paymentData => await requestApi('/pledge2', 'POST', paymentData);

// API request to call the backend
export const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}
) => {
  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "site" folder, in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${config.domains.api}${path}`

  // Set headers
  headers = { 'Content-Type': 'application/json', ...headers };

  console.log('url:', url);
  console.log('payload:', JSON.stringify(data));
  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body: data ? JSON.stringify(data) : null
  })

  if (!response.ok) {
    let error;
    try {
      error = await response.json();
    } catch (e) {
      error = { error: `${ method } ${ path } returned ${ response.status }` };
    }
    throw new Error(error.error);
  }

  return await response.json();
}

// Authenticated API request to call the backend
export const requestAuthenticatedApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}
) => {
  const session = getSession();
  const userToken = session?.userToken;

  if (userToken) {
    headers = { ...headers, Authorization: `Bearer ${userToken}` };
  }

  return await requestApi(path, method, data, headers);
};

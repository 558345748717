import React, { Component } from 'react'
import { default as withRouter } from "../../components/WithRouter/withRouter";

class Newsletter extends Component {
  render() {
    return (
      <div>This is where the newsletter page goes</div>
    )
  }
}
export default withRouter(Newsletter)
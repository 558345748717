import React from 'react';

import { Container, Form, Row, Col, Button, Spinner, Alert, Modal } from 'react-bootstrap';

import { postMessage } from '../../utils';
import { HashLink as Link } from 'react-router-hash-link';

import PropTypes from 'prop-types';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    // Just hard code these for now, based on type
    // Can make them props if we need more flexibility
    let preLink, postLink, header, subText, placeholder;

    if (props.type === 'problem') {
      preLink = 'Experiencing problems?';
      postLink = 'with a description of your issue.';
      header = 'Experiencing Problems?';
      subText = 'Please describe the issue below.';
      placeholder = 'What\'s the problem?';
    } else if (props.type === 'outsideBC') {
      preLink = 'Are you outside of B.C.?';
      postLink = 'directly.';
      header = 'Contact Us';
      subText = 'Tell us about yourself and where you live.';
      placeholder = 'Give us your details.';
    }

    this.state = {
      email: '',
      description: '',

      type: props.type || 'general',
      preLink: preLink || '',
      postLink: postLink || '',
      header: header || 'Contact Us',
      subText: subText || 'Give us the details.',
      placeholder: placeholder || 'What\'s up?',

      showModal: false,

      loading: false,
      errorMsg: ''
    };
  }

  setErrorMsg = errorMsg => {
    this.setState({
      loading: false,
      errorMsg: errorMsg || ''
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({
      loading: true,
      errorMsg: ''
    });

    try {
      await postMessage({ type: this.state.type, email: this.state.email, description: this.state.description });
      this.toggleShowModal();
      this.setErrorMsg('');
    } catch (error) {
      this.setErrorMsg(error.message);
    }
  }

  handleChange = event => {
    if (event.target.name) {
      this.setState({ [event.currentTarget.name]: event.currentTarget.value });
    }
  }

  toggleShowModal = event => {
    if (event?.preventDefault) event.preventDefault();

    this.setState({
      showModal: !this.state.showModal,
      errorMsg: ''
    });
  }

  render = () => {
    const { type, inline, ...props } = this.props;
    return (
      <React.Fragment>
        { type === 'problem' ?
          <span className='font-thin' { ...props }>
            { this.state.preLink }&nbsp;
            <button className='btn btn-link align-baseline p-0' onClick={ this.toggleShowModal }>Contact us</button> { this.state.postLink }
          </span>
          :
          <span className='font-thin' { ...props }>
            { this.state.preLink }&nbsp;
            <Link to='/faq#faq_10' className='btn btn-link align-baseline p-0'>Click here</Link>&nbsp;for more info or&nbsp;
            <button className='btn btn-link align-baseline p-0' onClick={ this.toggleShowModal }>contact us</button> { this.state.postLink }
          </span>
        }
        <Modal show={ this.state.showModal } onHide={ this.toggleShowModal }  backdrop='static'>
          <Modal.Header closeButton className='py-2'><b>{ this.state.header }</b></Modal.Header>
          <Container>
            <p className='pt-3'>{ this.state.subText } We'll get back to you as soon as possible.</p>
            <Form className='pb-3' onSubmit={ this.handleSubmit }>
              <Row>
                <Form.Group as={ Col } controlId='email'>
                  <Form.Label>Email Address *</Form.Label>
                  <Form.Control required name='email' value={ this.state.email }
                    onChange={ this.handleChange }
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={ Col } controlId='description'>
                  <Form.Label>Details *</Form.Label>
                  <Form.Control required name="description" as="textarea" rows={ 4 }
                    onChange={ this.handleChange }
                    placeholder={ this.state.placeholder }
                  />
                </Form.Group>
              </Row>
              { this.state.errorMsg &&
                <Alert variant='danger'>{ this.state.errorMsg }</Alert>
              }
              <Button className='' type='submit'>
              { this.state.loading &&
                <span><Spinner as='span' animation='grow' size='sm'/> Submitting...</span>
              }
              { !this.state.loading &&
                <span>Submit</span>
              }
              </Button>
            </Form>
          </Container>
        </Modal>
      </React.Fragment>
    )
  }
};

Contact.propTypes = {
  type: PropTypes.string,
  inline: PropTypes.bool
};

export default Contact;

import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import styles from './Header.module.css'

import { getSession, deleteSession } from '../../utils';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      session: null
    };
  }

  async componentDidMount() {
    this.setState({
      session: getSession(),
    });
  }

  logOut = () => {
    deleteSession();
    this.setState({
      session: null,
    });
    window.location.replace('/');
  }

  render() {
    return (
      <Navbar fixed="top" bg="white" expand="md" className={styles.navbar}>
        <Navbar.Brand href="/">
          <img alt="" src="/reciprocity_trusts_logo.svg" className="d-inline-block align-top"/>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end font-thin">
          <Nav className={styles.nav}>
            <NavDropdown title="Get Started" className={styles.navMenu}>
              <NavDropdown.Item href="im-a-homeowner">I'm a homeowner</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="im-a-renter">I'm a renter</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="im-a-business-owner">I'm a business owner</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="im-something-else">I'm hosting an event</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="im-indigenous">I'm Indigenous</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Learn More" className={styles.navMenu}>
              <NavDropdown.Item href="what-is-reciprocity">What is Reciprocity?</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="how-r-works">How Reciprocity Trusts Work</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="reciprocity-nations">Reciprocity Nations</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="reciprocity-map">Reciprocity Map</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="acts-of-reciprocity">Acts Of Reciprocity</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="backyard-restoration">Backyard Restoration</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="faq">FAQ</NavDropdown.Item>
              <hr className='m-0' />
              <NavDropdown.Item href="get-in-touch">Get In Touch</NavDropdown.Item>
              {/* <NavDropdown.Item href="newsletter">Newsletter</NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link href="our-team" className={styles.navMenu}>Our Story</Nav.Link>
            {/* <NavDropdown alignRight title={<i className="fa fa-user"></i>} className={styles.navMenu}>
              {!this.state.session &&
                <NavDropdown.Item href="login">Log In</NavDropdown.Item>
              }
              {this.state.session &&
                <React.Fragment>
                  {this.state.session.admin &&
                    <NavDropdown.Item href="/admin">Admin</NavDropdown.Item>
                  }
                  <NavDropdown.Item href="/account">My Account</NavDropdown.Item>
                  <NavDropdown.Item onClick={this.logOut}>Log Out</NavDropdown.Item>
                </React.Fragment>
              }
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;

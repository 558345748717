import React from 'react';

import { Container, Button } from 'react-bootstrap';
import FancyR from '../../components/FancyR/FancyR'

import PropTypes from 'prop-types';

import styles from './Success.module.css';


class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      years: 0,
      pledgeOnly: this.props.pledgeOnly
    };
  }

  componentDidMount = () => this.setState({ years: this.props.years || 0 });

  renderSuccess = type => {
    switch (type) {
      case 'homeowner':
        return this.renderHomeownerRenter();
      case 'renter':
        return this.renderHomeownerRenter();
      case 'business':
        return this.renderBusinessEvent();
      case 'other':
        return this.renderBusinessEvent();
      default:
        return this.renderHomeownerRenter();
    }
  }

  renderHomeownerRenter = () => {
    return (
      <section>
        <h3 className='text-center'>
          {this.state.pledgeOnly ?
            <>
              <strong>
                <p>
                  Thank you for joining the Reciprocity revolution. Together, we are building a community that is working to make Indigenous land, language and rights more visible, and recognition more real. We are not yet operational in your area, but we will reach out as soon as we get things up and running!
                </p>
              </strong>
            </>
            :
            <>
              <strong>
                <p>
                  Thank you for joining the Reciprocity revolution. Together, we are building a community that is working to make Indigenous land, language and rights more visible, and recognition more real. You are now starting a new tradition of more meaningfully saying thank you for the beautiful Indigenous lands where we are privileged to live, work and play.
                </p>
              </strong>
              <p className='font-thin'>
                We’ll be in touch via email with your receipt, as well as to confirm the mailing address for your reciprocity swag so that you can show off your new <FancyR text="home" /> certification.
              </p>
            </>
          }
        </h3>
      </section>
    )
  }

  renderBusinessEvent = () => {
    return (
      <section>
        <h3 className='text-center'>
          <>
            <strong>
              <p>
                Thanks for your interest in joining the Reciprocity revolution. Together, we are building a community that is working to make Indigenous land, language and rights more visible, and recognition more real. We’ve received your info and will be in touch soon to discuss your unique business or event.
              </p>
            </strong>
          </>
        </h3>
      </section>
    )
  }

  render() {
    const body = "I%20just%20joined%20Reciprocity%2C%20a%20simple%2C%20direct%20and%20fun%20way%20to%20meaningfully%20recognize%20Indigenous%20land%20rights%20and%20express%20gratitude%20for%20where%20we%20live.";
    const url = "https%3A%2F%2Freciprocitytrusts.ca";
    return (
      <Container className={styles.container}>
        <section className='text-center'>
          <h1 className='marginBottom25'>
            <span className='reciprocityGreenText'>Congratulations!</span>
          </h1>
        </section>

        {this.renderSuccess(this.props.type)}

        <section className='text-center'>
          <div className='m-auto pt-3'>
            <Button variant='light' target='_blank' rel='noopener noreferrer' href={`https://www.facebook.com/sharer/sharer.php?quote=${body}&u=${url}`}>
              <i className="fa fa-facebook-square fa-3x"></i>
            </Button>
            <Button variant='light' target='_blank' rel='noopener noreferrer' href={`https://twitter.com/intent/tweet?url=${body}%0A%0A${url}`}>
              <i className="fa fa-twitter-square fa-3x"></i>
            </Button>
            <Button variant='light' target='_blank' rel='noopener noreferrer' href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}>
              <i className="fa fa-linkedin-square fa-3x"></i>
            </Button>
            <Button variant='light' target='_blank' rel='noopener noreferrer' href={`mailto:?subject=Reciprocity%20Trusts!&body=${body}%0A%0A${url}`}>
              <i className="fa fa-envelope-square fa-3x"></i>
            </Button>
          </div>
          <p className='text-muted pt-3'>Thank you for joining our Reciprocity revolution.</p>
          <p className='text-muted pb-3'>Want to talk? Give us a call at <a href='tel:778-606-2511;ext=1'>778.606.2511 ext. 1</a>.</p>
          {/* <Button variant='light' href='/account' className={styles.dashboardButton}>Go to your account</Button> */}
        </section>
      </Container>
    )
  }
}

Success.propTypes = {
  type: PropTypes.string.isRequired,
  years: PropTypes.number
};

export default Success;

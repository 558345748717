import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { default as withRouter } from "../../components/WithRouter/withRouter";

import './BackyardRestoration.css';

class BackyardRestoration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      session: null
    };
  }

  async componentDidMount() {
  }

  openAccordionByUrl() {
    const tag = window.location.href.split('#section_')[1];
    return tag;
  }


  render() {
    return (
      <>
        <div className="titleContainer">
          <img
            src="/backyard_restoration_title.svg"
            alt="Backyard Restoration"
            className="titleImage"
          >
          </img>
        </div>
        <div className="container">
          <div >
            <h2 className="mb-5">
              Working towards eco-cultural restoration of native ecosystems in
              your own back yard is a fun and impactful way to:
            </h2>
            <img
              src="/backyard_restoration_2.svg"
              alt="Backyard Restoration info"
              className=" text-center marginBottom100 w-100"
            >
            </img>
          </div>
          <h2 className="mb-4">Backyard Restoration Guides & Resources</h2>
          <p className="mb-4 text-muted font-thin font-weight-light">
            With the help of our friends at <a target='_blank' rel='noopener noreferrer' href='https://reciprocityconnects.ca/restoration/'>Reciprocity Restoration</a>, we’ve curated a
            number of backyard restoration guides and resources, as well as a list of
            local organizations engaged in respectful eco-cultural restoration that are
            always looking for volunteers!
          </p>
          <div className='mb-5'>
            <Accordion defaultActiveKey={this.openAccordionByUrl}>
              <a className='anchor' id='section`_0'></a>
              <Accordion.Item className="faq-card" eventKey="0">
                <Accordion.Header className='pointer'>
                  <b>
                    Guides & Resources
                  </b>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className='text-muted font-thin font-weight-light'>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://npsg.ca/learn-about-native-plants/'>Learn About Native Plants</a>&nbsp;with the Native Plant Study Group
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.saanich.ca/EN/main/community/natural-environment/naturescape-program.html'>District of Saanich Naturescape Program & Resources
                      </a>
                    </li>
                    <li>
                      Garry Oak Ecosystems Recovery Team has created&nbsp;<a target='_blank' rel='noopener noreferrer' href='https://goert.ca/wp/wp-content/uploads/GOERT-Gardeners-Handbook-2011.pdf'>The Garry Oak Gardener’s
                        Handbook: Nurturing Native Plant Habitat in Garry Oak Communities
                      </a>
                    </li>
                    <li>Habitat Acquisition Trust&nbsp;<a target='_blank' rel='noopener noreferrer' href='https://hat.bc.ca/gardeningwithnature'>Gardening with Nature
                    </a>
                      <ul>
                        <li>
                          Includes information on naturescaping, creating wildlife habitat, and invasive
                          species removal.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://wmswcd.org/wp-content/uploads/2016/04/Meadowscaping_Publication_Complete_LR.2.pdf'>The Meadowscaping Handbook
                      </a>&nbsp;by West Multnomah
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.bcnativebees.org/forage-resources'>Forage Resources for Specialist Bees in BC
                      </a>&nbsp;by the Native Bee Society of BC
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.crd.bc.ca/education/natural-gardening'>CRD Natural Gardening Resources
                      </a>
                      <ul>
                        <li>
                          Tip: Toggle through the different pages (e.g. Native Plants or Gardening for
                          Wildlife) and view a list of resources down the right side of each page.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://static1.squarespace.com/static/5e3c5b7e5460c55405a6d4d6/t/6269b684534e5c386d7ff2a9/1651095176037/Creating+a+native+Garden+Design+to+Maintenance+Goulet+Nov+2015.pdf'>Creating a Native Garden in Victoria BC
                      </a>&nbsp;(Louise Goulet, 2015)
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://bcinvasives.ca/wp-content/uploads/2021/01/ISCBC-GMI-Brochure-180425-WEB.pdf'>Grow Me Instead
                      </a>&nbsp;by the Invasive Species Council of BC
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://pollinatorpartnership.ca/en/psc-vancouver-island'> Vancouver Island Pollinator Steward Webinars
                      </a>&nbsp;by Pollinator Partnership Canada
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <a className='anchor' id='section`_1'></a>
              <Accordion.Item className="faq-card" eventKey="1">
                <Accordion.Header className='pointer'>
                  <b>
                    Workshops & Restoration Events
                  </b>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className='mb-5 text-muted font-thin font-weight-light'>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://satinflower.ca/pages/workshops-events'> Satinflower Nurseries' Workshops & Events
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='Habitat Acquisition Trust Native Plant Salvage Training'>Habitat Acquisition Trust Native Plant Salvage Training
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.swanlake.bc.ca/workshops/'>Swan Lake Christmas Hill Nature Sanctuary Workshops
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://npsg.ca/'>Native Plant Study Group Events
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.crd.bc.ca/education/natural-gardening/native-plants/native-plant-gardening-workshops'>CRD Embracing the Wild: Native Plant Workshop
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://hcp.ca/adult-community-education/'>The Horticulture of the Pacific Workshops & Events
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <a className='anchor' id='section`_2'></a>
              <Accordion.Item className="faq-card" eventKey="2">
                <Accordion.Header className='pointer'>
                  <b>
                    Connect with Others
                  </b>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className='mb-5 text-muted font-thin font-weight-light'>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/groups/2108926929123401'>Satinflower Nurseries' Facebook Restoration Group
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/nativeplantstudygroup/'>Native Plant Study Group Facebook Group
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <a className='anchor' id='section`_3'></a>
              <Accordion.Item className="faq-card" eventKey="3">
                <Accordion.Header className='pointer'>
                  <b>
                    Get Involved
                  </b>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className='mb-5 text-muted font-thin font-weight-light'>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.pepakenhautw.com/volunteer'>PEPAKEṈ HÁUTW̱ Foundation
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.wsikemivyproject.com/'>W̱SÍ,KEM Ivy Project
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://wmiyetennaturesanctuary.com/volunteer/'>W̱ MÍYEŦEN Nature Sanctuary
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.sandowncentre.com/workshops-tours-and-events'>Sandown Centre for Regenerative Agriculture
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://hat.bc.ca/volunteer#block-yui_3_17_2_1_1699050345077_12259'>Habitat Acquisition Trust
                      </a>
                      <ul>
                        <li>
                          Also their&nbsp;<a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/HabitatAcqTrust/'>Facebook page</a>&nbsp;for other events in the community
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.swanlake.bc.ca/support-the-sanctuary/volunteer/'>Swan Lake Christmas Hill Nature Sanctuary
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/CommunityToolShed/'>
                        lək̓ ʷəŋən Community Toolshed
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.saanich.ca/EN/index/notification/notification-page.html?url=https%3a%2f%2fwww.saanich.ca%2fEN%2fmain%2fparks-recreation-community%2fparks%2fnatural-areas%2fvolunteer-for-pulling-together.html'>
                        District of Saanich Pulling Together
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.meetup.com/greater-victoria-green-team/events/'>
                        Greater Victoria Green Team
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/p/Friends-of-North-Saanich-Parks-61552132509949/'>
                        Friends of North Saanich Parks
                      </a>
                    </li>
                    <li>
                      <a target='_blank' rel='noopener noreferrer' href='https://peninsulastreams.ca/join-us/volunteer-opportunities/'>
                        Peninsula Streams and Shorelines
                      </a>
                    </li>
                    <li>
                      Gulf Island organizations:
                      <ul>
                        <li>
                          <a target='_blank' rel='noopener noreferrer' href='https://galianoconservancy.ca/get-involved/volunteer/'>
                            Galiano Island Conservancy Association
                          </a>
                        </li>
                        <li>
                          <a target='_blank' rel='noopener noreferrer' href='https://www.penderconservancy.org/make-a-difference'>
                            Pender Island Conservancy
                          </a>
                        </li>
                        <li>
                          <a target='_blank' rel='noopener noreferrer' href='https://mayneconservancy.ca/volunteer/'>
                            Mayne Island Conservancy
                          </a>
                        </li>
                        <li>
                          <a target='_blank' rel='noopener noreferrer' href='https://saltspringconservancy.ca/get-involved/volunteer/'>
                            Salt Spring Island Conservancy
                          </a>
                        </li>
                        <li>
                          <a target='_blank' rel='noopener noreferrer' href='http://www.ssiwaterpreservationsociety.ca/join--volunteer.html'>
                            Salt Spring Island Water Preservation Society
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div >
      </>
    );
  }
}

export default withRouter(BackyardRestoration);
import React from 'react';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import './Flycatcher.css'
import { postPledge2 } from '../../utils';
import CloseIcon from '@material-ui/icons/Close';

class Flycatcher extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      loading: false,
      errorMsg: '',
      display: false,
      submitted: false

    }
    // Disable flycatcher
    // setTimeout(() => {
    //   this.setState({ display: true })
    // },
    //   15000);
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit = async event => {
    event.preventDefault();

    await postPledge2(this.state);

    this.setState({
      submitted: true
    });
  }

  handleClick() {
    this.setState({
      display: false
    })
  }

  render = () => {
    return (
      <Modal show={this.state.display} className='flycatcher-modal' backdrop='static' dialogClassName="flycatcher-dialog" contentClassName="flycatcher-content">
        <div className="text-right">
          <button onClick={this.handleClick} className='link'>
            <CloseIcon style={{ color: 'white' }} />
          </button>
        </div>
        <Modal.Body>
          <h2 className='text-center font-thin mb-5'>Welcome to Reciprocity Trusts!</h2>
          <h3 className=' font-thin mb-4' >Would your household or business be interested in making a regular voluntary Reciprocity payment (around $400 a year for the average household) to recognize and support the Indigenous Nations on whose lands you live and work?</h3>
          <h3 className='font-thin mb-4'>If the answer is yes, pledge to join Reciprocity by filling in your info below. We'll let you know once we're up and running in your area. <em>NB: We will be launching on Southern Vancouver Island on June 1st!</em></h3>
          {!this.state.submitted &&
            <Form onSubmit={this.handleSubmit} className='flycatcher-form'>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control name="firstName" onChange={this.handleChange} type="text" required />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control name="lastName" onChange={this.handleChange} type="text" required />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group controlId="email">
                    <Form.Label>E-Mail Address *</Form.Label>
                    <Form.Control name="email" onChange={this.handleChange} type="email" required />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group controlId="city">
                    <Form.Label>City/Town</Form.Label>
                    <Form.Control name="city" onChange={this.handleChange} type="text" required />
                  </Form.Group>
                </Col>
              </Row>
              <div className='text-center'>
                <p>Please fill out the form above.</p>
                <Button type="submit" className='sign-up mb-5' >Pledge to join Reciprocity</Button>
              </div>
              <div className='text-center'>
                <p>
                  <button className='visit-site' onClick={this.handleClick}>Not ready yet or want to learn more? Visit the site by clicking here.</button>
                </p>
              </div>
              <br />
              <div className='text-center'>
                <img alt="" src="/reciprocity-primary-white.png" className="d-inline-block align-top flycatcher-img" />
              </div>
            </Form>
          }
          {this.state.submitted &&
            <div className='text-center'>
              <p>Thank you for your submission!</p>
              <Button type="submit" onClick={this.handleClick} className='sign-up mb-5'>Continue to Reciprocity site</Button>
            </div>
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default Flycatcher;
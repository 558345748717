import React from 'react';

import { Container, Row, Col, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';

import Assessment from '../../components/Assessment/Assessment';
import Contact from '../../components/Contact/Contact';
import CurrencyInput from '../../components/CurrencyInput/CurrencyInput';
import AddressLookup from '../../components/AddressLookup/AddressLookup';
import MunicipalitySelect from '../../components/MunicipalitySelect/MunicipalitySelect';
import { HashLink as Link } from 'react-router-hash-link';
import FancyR from '../../components/FancyR/FancyR';

import { getSession, getMunicipalities, postPledge, getRuralById } from '../../utils';

import PropTypes from 'prop-types';

import styles from './Registration.module.css';
import GetStartedSelect from '../../components/GetStartedSelect/GetStartedSelect'

export const RegistrationTypes = {
  homeowner: 'homeowner',
  renter: 'renter',
  business: 'business',
  other: 'other',
  indigenous: 'indigenous',
};

// The "not found" municipality: defaults to the rft region
const notHere = { value: '-1', label: 'My Area\'s Not Here', notHere: true, region: 'rgn_1', partner: '', mun_rate: '0', rural_rate: '5' };
const CRDRenter = { value: '-2', label: 'Capital Regional District', notHere: false, regionId: 'rgn_5', partner: '', mun_rate: '0', rural_rate: '5' };

class Registration extends React.Component {
  constructor(props) {
    super(props);

    const session = getSession();

    this.state = {
      type: this.props.type,
      newsletter: this.props.type !== RegistrationTypes.indigenous ? true : false,
      email: '',
      rural: false,

      address: '',
      municipality: null,

      municipalities: [],
      session: session,

      showAssessment: false,
      showRural: false,
      ruralAreaCode: null,
      ruralArea: null,

      loading: false,
      errorMsg: '',

      community: ''
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ loading: true });
    await this.getMunicipalities();
    this.setState({ loading: false });
  }

  getMunicipalities = async () => {
    try {
      const result = await getMunicipalities();
      const municipalities = [notHere, ...result.municipalities];
      if (this.props.type === RegistrationTypes.renter) {
        municipalities.splice(1, 0, CRDRenter);
      }
      this.setState({ municipalities: municipalities });
    } catch (error) {
      this.setState({
        loading: false,
        errorMsg: error.message
      });
    }
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleNewsletterChange = () => {
    this.setState({ newsletter: !this.state.newsletter });
  }

  handleRuralChange = () => {
    // dont forget to reset the municipality
    this.setState({ rural: !this.state.rural, showRural: !this.state.rural,  municipality: undefined });
    if (!this.state.rural) {
      this.setState({ ruralAreaCode: undefined, ruralArea: undefined });
    }
  }

  handleRuralAreaChange = async (evt) => {
    this.setState({ ruralArea: await getRuralById(evt.target?.value) || null });
  }
    

  handleCurrencyChange = (name, value) => {
    if (!value) value = '';
    this.setState({ [name]: value });
  }

  handleAddressChange = (address, geoCode) => {
    // Get the municipality from the geoCode by running up the chain of
    // political components of the address, keeping the highest hit
    let municipality = notHere;
    let postalCode = '';
    geoCode.address_components.forEach(component => {
      if (component.types.includes('postal_code')) {
        postalCode = component.long_name;
        return;
      }
      if (!component.types.includes('political')) return;
      const found = this.state.municipalities.find(municipality => municipality.label === component.long_name);
      if (found) municipality = found;
    });

    this.setState({
      address: address,
      municipality: municipality,
      postalCode: postalCode,
      lat: geoCode.geometry.location.lat(),
      lng: geoCode.geometry.location.lng(),
    });
  }

  handleMunicipalityChange = municipality => {
    this.setState({
      municipality: municipality
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({
      loading: true,
      errorMsg: ''
    });

    const { loading, errorMsg, session, municipalities, showAssessment, showRural, email, newsletter, community, ...form } = this.state;

    console.log(session)

    const submitObject = {
      createdAt: Date.now(),
      amount: 0,
      currency: '',
      email: email,
      regionName: '',
      term: '',
      description: form.description || '',
      public: '',
      publicMap: 0,
      total: '',
      registration: {
        type: this.props.type,
        unit: form.unit || '',
        address: form.address || '',
        municipality: form.municipality || '',
      },
      newsletter: newsletter,
      community: community
    };

    // Register the form first
    try {
      if (this.props.type === RegistrationTypes.business || this.props.type === RegistrationTypes.other || this.props.type === RegistrationTypes.indigenous) {
        await postPledge(submitObject);
      }
    } catch (error) {
      this.setState({
        loading: false,
        errorMsg: error.message
      });
      return;
    }

    // All good!
    this.props.onSuccess(form);
  }

  toggleAssessment = event => {
    if (event?.preventDefault) event.preventDefault();

    this.setState({ showAssessment: !this.state.showAssessment });
  }

  toggleRural = event => {
    if (event?.preventDefault) event.preventDefault();

    this.setState({ showRural: !this.state.showRural });
  }

  render() {
    const { subText, image, type, submitText } = { ...this.props };

    return (
      <Container>
        <div className="marginBottom25">
          <GetStartedSelect type={type} />
        </div>
        <div className="marginBottom25 font-thin">
          {subText}
          {' '}
          {(type === RegistrationTypes.homeowner) &&
            <Link to='/how-r-works#whatHappensNext' target="_blank">
              <button className="btn btn-link align-baseline p-0"> Where would my dollars go?</button>
            </Link>
          }
          {(type === RegistrationTypes.renter) &&
          <span>
            Learn more about&nbsp;
            <Link className="font-thin" to='/how-r-works#whatHappensNext' target='_blank'>
              <button className="btn btn-link align-baseline p-0"> where Reciprocity Payments go.</button>
            </Link>
          </span>
          }
        </div>


        {(type === RegistrationTypes.business || type === RegistrationTypes.other) &&
          <>
            <p className='font-thin'>
              Because this is so variable, we don't have a calculator for this. <strong>We'll be in touch personally to work on next steps customized to your business or event.</strong> At this point in time, we are only working with businesses and events operating primarily on Southern Vancouver Island. If you are located elsewhere, please reach out to us so we can let you know when we are up and running in your area.
            </p>
            <Link className='btn btn-link align-baseline p-0' to='/how-r-works#whatHappensNext' target="_blank">Where would my dollars go?</Link>
          </>
        }

        <p className={`marginBottom100`}>
          {(type === RegistrationTypes.homeowner || type === RegistrationTypes.renter) &&
            <React.Fragment>{' '}<Contact inline type='outsideBC' /></React.Fragment>
          }
        </p>
        <Row className="marginBottom50">
          <Col className="text-center reciprocityLightBlueText" lg={4}>
            <Image className={`${styles.thumbnail} mb-4`} src={image} thumbnail />
            <h3>reciprocity.</h3>
            {(type === RegistrationTypes.homeowner || type === RegistrationTypes.renter) &&
              <h3>home.</h3>
            }
            {type === RegistrationTypes.business &&
              <h3>business.</h3>
            }
          </Col>
          <Col lg={8}>
          <Form onSubmit={this.handleSubmit}>
              {(this.props.type === RegistrationTypes.business || this.props.type === RegistrationTypes.other || this.props.type === RegistrationTypes.indigenous) &&
                <div>
                  <Row>
                    <Col xs={6}>
                      <Form.Group controlId="firstName">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control name="firstName" onChange={this.handleChange} type="text" required />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="lastName">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control name="lastName" onChange={this.handleChange} type="text" required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <Form.Group controlId="email">
                        <Form.Label>E-Mail Address *</Form.Label>
                        <Form.Control name="email" onChange={this.handleChange} type="email" required />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control name="phone" onChange={this.handleChange} type="text" />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              }

              {/* {(type === RegistrationTypes.business || type === RegistrationTypes.other) &&
                <Row>
                  <Form.Check className={styles.signUp} type='checkbox' id='signUp' label="Sign up for our newsletter"
                    checked={this.state.newsletter} onChange={this.handleNewsletterChange}
                  />
                </Row>
              } */}

              {(type === RegistrationTypes.indigenous) &&
                <Row className="mx-1">
                  <Form.Label>Let us know which First Nation or Indigenous community you're from:</Form.Label>
                  <Form.Control name="community" onChange={this.handleChange} type="text" />
                </Row>
              }

              {(type === RegistrationTypes.homeowner || type === RegistrationTypes.renter) &&
                <Row>
                  <Col xs={8} sm={9}>
                    <Form.Group controlId="address">
                      <Form.Label>Address *</Form.Label>
                      <AddressLookup onChange={this.handleAddressChange} required />
                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={3}>
                    <Form.Group controlId="unit">
                      <Form.Label>Unit Number</Form.Label>
                      <Form.Control name="unit" onChange={this.handleChange} type="text" />
                    </Form.Group>
                  </Col>
                </Row>
              }

              {type === RegistrationTypes.business &&
                <Form.Group controlId="companyName">
                  <Form.Label>Company Name *</Form.Label>
                  <Form.Control name="companyName" onChange={this.handleChange} type="text" required />
                </Form.Group>
              }

              {type === RegistrationTypes.homeowner &&
                <Row >
                  <Col xs={12} md={9}>
                    <Form.Group controlId="municipality">
                      <Form.Label>To which municipality do you pay property taxes? *</Form.Label>
                      <MunicipalitySelect value={this.state.municipality} disabled={this.state.rural}
                        options={this.state.municipalities} onChange={this.handleMunicipalityChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3} className='align-self-end'>
                    <Form.Check className={styles.rural} type='checkbox' id='rural' label="I'm in a rural area"
                      checked={this.state.rural} onChange={this.handleRuralChange}
                    />
                  </Col>
                </Row>
              }

              {type === RegistrationTypes.renter &&
                <Form.Group controlId="municipality">
                  <Form.Label>Municipality or Regional District*</Form.Label>
                  <MunicipalitySelect value={this.state.municipality}
                    options={this.state.municipalities} onChange={this.handleMunicipalityChange}
                  />
                </Form.Group>
              }

              {type === RegistrationTypes.business &&
                <Form.Group controlId="municipality">
                  <Form.Label>Municipality where you are headquartered</Form.Label>
                  <MunicipalitySelect value={this.state.municipality}
                    options={this.state.municipalities} onChange={this.handleMunicipalityChange}
                  />
                </Form.Group>
              }

              {type === RegistrationTypes.other &&
                <Form.Group controlId="municipality">
                  <Form.Label>Municipality where your event will take place</Form.Label>
                  <MunicipalitySelect value={this.state.municipality}
                    options={this.state.municipalities} onChange={this.handleMunicipalityChange}
                  />
                </Form.Group>
              }

              {this.state.rural &&
                <Row >
                  <Col xs={5} sm={4} md={3}>
                    <Form.Group controlId="ruralAreaCode">
                      <Form.Label>Rural Area Code *</Form.Label>
                      <Form.Control name="ruralAreaCode" onChange={this.handleRuralAreaChange} type="text" required={this.state.rural} value={this.state.ruralAreaCode}/>
                    </Form.Group>
                  </Col>
                  <Col xs={7} sm={6} md={4} className='align-self-end'>
                    <div className={styles.ruralInfo}>
                      <i className="fa fa-info-circle pr-2"></i>
                      <button className='btn btn-link align-baseline p-0 mb-4' onClick={this.toggleRural}>Find your rural area code</button>
                    </div>
                  </Col>
                </Row>
              }

              {type === RegistrationTypes.homeowner &&
                <Row >
                  <Col xs={7} sm={5} md={4}>
                    <Form.Group controlId="value">
                      <Form.Label>Current Property Value *</Form.Label>
                      <Form.Control as={CurrencyInput} name="value" onChange={this.handleCurrencyChange} value={this.state.value} required />
                    </Form.Group>
                  </Col>
                  <Col xs={7} sm={5} md={4} className='align-self-end'>
                    <div className={styles.info}>
                      <i className="fa fa-info-circle pr-2"></i>
                      <button className='btn btn-link align-baseline p-0' onClick={this.toggleAssessment}>Find your property value</button>
                    </div>
                  </Col>
                </Row>
              }

              {type === RegistrationTypes.renter &&
                <Form.Group controlId="rent">
                  <Form.Label>Monthly Rent *</Form.Label>
                  <Form.Control as={CurrencyInput} name="rent" onChange={this.handleCurrencyChange} value={this.state.rent} required />
                </Form.Group>
              }

              {(type !== RegistrationTypes.indigenous) &&
                <Row>
                  <Form.Check className={styles.signUp} type='checkbox' id='signUp' label="Sign up for our newsletter"
                    checked={this.state.newsletter} onChange={this.handleNewsletterChange}
                  />
                </Row>
              }

              {type === RegistrationTypes.business &&
                <Form.Group controlId="description">
                  <Form.Label>Business Description</Form.Label>
                  <Form.Control name="description" onChange={this.handleChange} as="textarea" rows={2} placeholder="Tell us a bit about your business and where you operate" />
                </Form.Group>
              }

              {type === RegistrationTypes.other &&
                <Form.Group controlId="description">
                  <Form.Label>Event Description</Form.Label>
                  <Form.Control name="description" onChange={this.handleChange} as="textarea" rows={2} placeholder="Tell us a bit about your event or what you are up to" />
                </Form.Group>
              }

              <div className={styles.requiredTag}>* Required field</div>
              {this.state.errorMsg &&
                <Alert variant='danger'>{this.state.errorMsg}</Alert>
              }
              <Button className='mb-3' type='submit'>
                {this.state.loading &&
                  <span>
                    <Spinner as='span' animation='grow' size='sm' /> Processing Your Submission...
                  </span>
                }
                {!this.state.loading &&
                  <span>
                    {submitText}
                  </span>
                }
              </Button>
            </Form>
            <Contact type='problem' className='text-center pt-2' />

            {type === RegistrationTypes.other &&
              <section className='marginTop50 font-thin'>
                <h4 className='text-center'>What comes next?</h4>
                <span>
                  If you make the recommended Reciprocity payment for your event, your event will be certified as an <FancyR text='event'/>. This includes:
                  <ul>
                    <li>
                      recognition as an <FancyR text='event'/>, including branding and marketing tools to help communicate that your event is going above and beyond the ceremonial recognition and acknowledgement of Indigenous land rights.
                    </li>
                    <li>
                      connection with the Reciprocity community - on line, and in real life - including connections to Reciprocity Nations, your Reciprocity Trust, and invitations to Reciprocity events near you.
                    </li>
                    <li>
                      Annual reporting (usually sent out around April) to keep you informed, and let you see where your Reciprocity dollars are going. 
                    </li>
                    <li>
                      Info on other things you and your event participants can do to support and recognize Indigenous lands and rights in your community
                    </li>
                  </ul>
                  Your contribution qualifies for a tax deductible receipt you can claim when you file your tax return.  We’ll work with your Reciprocity Trust and Reciprocity Nations in your area to get that to you. We usually send tax receipts out digitally in January or February. 
                </span>
              </section>
            }

          </Col>
        </Row>
        <Assessment show={this.state.showAssessment} onHide={this.toggleAssessment} />
        <Assessment show={this.state.showRural} onHide={this.toggleRural} rural />
      </Container>
    )
  }
}

Registration.propTypes = {
  subText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  image: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  submitText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Registration;

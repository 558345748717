import React from 'react';

import CurrencyText from '../CurrencyText/CurrencyText';

import styles from './PaymentSelectButton.module.css';

class PaymentSelectButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      term: this.props.type === 'monthly' ? 'Monthly' : '',
    };
  }

  handleClickEvent = () => {
    this.props.setActivePaymentType(this.props.type);
  }

  render() {
    const { isSelected, address, paymentValue, year } = { ...this.props }
    return (
      <div
        className={ `${ styles.paymentSelectButton } ${ isSelected ? styles.customCardSelected : styles.customCard }` }
        onClick={ this.handleClickEvent }>
        <h1>
          <CurrencyText value={ paymentValue }/>
        </h1>
        <div className={ styles.recommended }>
          Recommended { this.state.term } { year } Reciprocity Payment
        </div>
        <div className={ styles.address }>
          for { address }
        </div>
      </div>
    )
  }
}

export default PaymentSelectButton;

import React from 'react'
import GA4React from 'ga-4-react';
import App from './App'
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { sendPing } from './utils';
import { createRoot } from 'react-dom/client';

const ga4react = new GA4React('G-BKM3KG20R8');

/**
 * Render App
 */

(async _ => {
  try {
    await ga4react.initialize();
  } catch (e) {
    console.log('Error', e);
    try {
      await sendPing();
    } catch (ee) {
    }
  }

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />)

})();
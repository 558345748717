/**
 * Global Config
 */

const config = {}

// Domains
config.domains = {}

/**
 * API Domain
 * Add the domain from your serverless express.js back-end here.
 * This will enable your front-end to communicate with your back-end.
 * (e.g. 'https://api.mydomain.com' or 'https://091jafsl10.execute-api.us-west-2.amazonaws.com')
 */
// DEV
// config.domains.api = 'https://tcdy5g7g1h.execute-api.ca-central-1.amazonaws.com/Prod'
// PROD
config.domains.api = 'https://aqe7t1ba13.execute-api.ca-central-1.amazonaws.com/Prod'

// Stripe
config.stripe = {}

// TEST
// config.stripe_publishable_key = 'pk_test_Ir3muWfBq89I5VueRuX0aRO200awaF97GS';

// PROD
config.stripe_publishable_key = 'pk_live_YFc00Tv5meEQIfbkzv0oZSkK00QopZwQeV';

// NON-RECIP STRIPE ACCT
// config.stripe_publishable_key = 'pk_test_51I4wsrGNiLPK5axkEohY20fume8qvzVkio8BkPey60BDxlQ4mtxfORxLmSXSdcsOwt3rLdSvXU8zCTP689NIMXPa00v2SMGJd8';

export default config
import { LatLng } from 'leaflet';
import proj4 from 'proj4';

import { getRegions, getRuralById } from './api';

export const loadRegionsGeoJSONs = async (regions, featureFunc) => {
  // Initialize BC standard projection
  proj4.defs('EPSG:3005', '+proj=aea +lat_1=50 +lat_2=58.5 +lat_0=45 +lon_0=-126 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

  await Promise.all(regions.map(async region => {
    if (!region.geojsonURL) return;

    const response = await fetch(region.geojsonURL);
    if (response.ok) {
      try {
        // Check for CRS conversion
        let projectionName = null;
        const json = await response.json();
        if (json.crs && json.crs.properties && json.crs.properties.name) {
          const crs = json.crs.properties.name.split(':').pop();
          if (['CRS84', 'WGS84', '4326'].includes(crs)) {
            projectionName = '';
          } else if (['3005'].includes(crs)) {
            projectionName = 'EPSG:3005';
          }
        }

        const converter = coords => {
          const newCoords = projectionName ? proj4(projectionName, 'WGS84', coords) : coords;
          return new LatLng(newCoords[1], newCoords[0], newCoords[2]);
        };

        if (featureFunc) {
          json.features.forEach(feature => featureFunc(region, feature));
        }

        region.json = json;
        region.converter = converter;
      } catch (e) {
        console.error(`fetching ${region.geojsonURL} ${e}`);
      }
    }
  }));
};

export const getRuralArea = async (ruralId) => {
  return await getRuralById(ruralId);
}

export const getRegionByRuralId = async (ruralId) => {
  try {
    let ruralArea = await getRuralArea(ruralId);
    let regions = await getRegions();
    return getRegionById(regions.regions, ruralArea.regionId);
  } catch (error) {
    return {};
  }
}

export const getRegionByKey = (regions, key) => {
  return regions.find(region => region.key === key) || null;
};

export const getRegionById = (regions, id) => {
  return regions.find(region => region.id === id) || null;
};

export const getRegionFromMunicipality = async municipality => {
  try {
    const result = await getRegions();
    return getRegionById(result.regions, municipality.regionId);
  } catch (error) {
    return {};
  }
}

import React from "react";

class REvent extends React.Component {
  render() {
    return (
      <strong className={this.props.size || "h5"}>
        <span className="reciprocityGreenText">r</span>
        <span className="reciprocityLightBlueText">.</span>
        {this.props.text && <span>&nbsp;{this.props.text}</span>}
        {this.props.plus && <sup className="text-danger">+</sup>}
      </strong>
    );
  }
}

export default REvent;

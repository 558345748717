import React from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types';

class MunicipalitySelect extends React.Component {
  render() {
    return (
      <Select name={ this.props.name || 'municipality' } value={ this.props.value } isDisabled={ this.props.disabled }
        options={ this.props.options } onChange={ this.props.onChange }
        styles={{ option: (provided, state) => ({ ...provided,
          color: state.data.notHere ? '#93ba21' : '#000',
          backgroundColor: state.data.notHere ? (state.isFocused ? '#ddd' : '#eee') : state.isFocused ? '#80bdff' : '#fff',
        })}}
      />
    );
  }
}

MunicipalitySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

export default MunicipalitySelect;

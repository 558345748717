import React, { Component } from 'react'
import styles from './FooterNewsletter.module.css'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { postNewsletter } from '../../utils/api'

class FooterNewsletter extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      registered: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNewsSignup = this.handleNewsSignup.bind(this);
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  async handleNewsSignup() {
    this.setState({
      loading: true
    })
    await postNewsletter(this.state);
    this.setState({
      loading: false,
      registered: true
    })
  }

  render() {
    const { loading, registered } = { ...this.state }
    return (
      <div className={`${styles.stayInTouchRow} container-fluid py-5`}>
        {
          loading === false && registered === false &&
          <div>
            <Row>
              <Col>
                <h2 className="mb-1">
                  {registered === false &&
                    <span>
                      Let's stay in touch!
                </span>
                  }
                </h2>
                {registered === false &&
                  <small>
                    Reciprocity respects your privacy and we'll keep your info safe.
                </small>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={`${styles.formContainer}`}>
                  <Form onSubmit={this.handleNewsSignup}>
                    <Row className={`${styles.formRow}`}>
                      <Col md className={`${styles.spacing}`}>
                        <Form.Control name='firstName' placeholder="First name*" onChange={this.handleChange} required />
                      </Col>
                      <Col md>
                        <Form.Control name='lastName' placeholder="Last name*" onChange={this.handleChange} required />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control name='email' placeholder="Email*" onChange={this.handleChange} required />
                      </Col>
                    </Row>
                    <br />
                    <button className={`${styles.customBtn}`}
                      type="submit"
                      size="lg"
                      block="true">Subscribe</button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        }
        {
          loading === true &&
          <div className={`h-100 w-100 d-flex flex-column justify-content-center align-items-center`}>
            <Spinner animation="grow" />
          </div>
        }
        {
          loading === false && registered === true &&
          <div className={`h-100 w-100 d-flex flex-column justify-content-center align-items-center`}>
            <h2>
              Thanks for signing up!
            </h2>
            <h5>
              You'll hear from us soon.
            </h5>
          </div>
        }
      </div >
    )
  }
}
export default FooterNewsletter

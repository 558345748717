import React from 'react';

import PropTypes from 'prop-types';

class RegionSummary extends React.Component {
  render = () => {
    const region = this.props.region ? this.props.region : { key: 'rft', id: null };
    return <React.Fragment>
      <p className='text-muted'>
        {region.key !== 'rft' &&
          <span>It looks like you would be part of the <b>{region.description}</b> area
            {region.territories &&
              <span>, which includes the traditional territories of the <b>{region.territories}</b></span>
            }
            .
          </span>
        }
        {region.key === 'rft' &&
          <span>It looks like you are outside of Reciprocity's current Regional Trust Area boundaries.</span>
        }
        {region.id !== 'rgn_5' &&
          <p className='text-muted mt-4'>
            Make a pledge to join Reciprocity once we are up and running in your area!
          </p>
        }
      </p>
    </React.Fragment>
  }
}

RegionSummary.propTypes = {
  region: PropTypes.object.isRequired
};

export default RegionSummary;

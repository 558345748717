import React from 'react';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import { Container } from 'react-bootstrap';

class ContactMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            position: [48.432217699239814, -123.36957113390392],
            zoom: 7,
            scrollWheelZoom: false,

        };
    }

    render = () => {
        return (
            <Container>
                <MapContainer className='mapContainer' center={this.state.position} zoom={15} scrollWheelZoom={this.state.scrollWheelZoom} maxZoom={15}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'>
                    </TileLayer>
                    <Marker position={this.state.position} >
                        <Popup>
                            <p className="mb-0"><strong>Main Office at KWENCH</strong><br/>
                                2031 Store St<br/>
                                Victoria BC V8T 5L9</p>
                        </Popup>
                    </Marker>
                </MapContainer>
            </Container>
        )
    }
}

export default ContactMap;
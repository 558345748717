import React from 'react';
import PropTypes from 'prop-types';

import animateHeadline from './animated-headline/animated-headline'

class AnimatedHeadline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      before: this.props.before || '',
      animated: this.props.animated || [],
      end: this.props.end || '',
      style: this.props.style || null,
    };
  }

  componentDidMount() {
    if (this.state.animated.length > 0) {
      animateHeadline();
    }
  }

  render() {
    return <span className='animated-headline'>
      <h1 className='text-anim text-anim--fade'>
        { this.state.before && <span>{ this.state.before }&nbsp;</span> }
        <span className='text-anim__wrapper'>
          {
            this.state.animated.map((piece, index) => {
              // Start with the first piece
              const className = 'text-anim__word' + (index ? '' : ' text-anim__word--in');
              // Load up optional props
              let props = {};
              if (this.state.style) props.style = this.state.style;

              return <span key={ index } className={ className } { ...props }>{ piece }</span>
            })
          }
        </span>
        { this.state.after && <span>&nbsp;{ this.state.after }</span> }
      </h1>
    </span>
  }
}

AnimatedHeadline.propTypes = {
  before: PropTypes.any,
  animated: PropTypes.array,
  after: PropTypes.any,
  style: PropTypes.object,
};

export default AnimatedHeadline;

import React from 'react'
import {
  Link
} from 'react-router-dom'
import { Alert, Container, Row, Col, Image, Carousel, Card, Button} from 'react-bootstrap'
import styles from './Home.module.css'
// import Banner from '../../components/Banner/Banner'
import Flycatcher from '../../components/Flycatcher/Flycatcher.jsx'
import { default as withRouter } from "../../components/WithRouter/withRouter";

import AnimatedHeadline from '../../components/AnimatedHeadline/AnimatedHeadline'

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      thankyou: props?.match?.path === '/thankyou'
    };
  }

  dismissThankyou = () => this.setState({ thankyou: false });

  render() {
    return (
      <div>
        <Flycatcher/>
        <Container fluid="lg">
          {this.state.thankyou &&
            <Alert className='text-center' dismissible onClose={this.dismissThankyou} variant='success'>Thanks for your interest in Reciprocity. We’ve received your info and will be in touch soon.</Alert>
          }
        </Container>
        {/* Banner removed by request as of 2024-06-20 https://reciprocityresearch.slack.com/archives/C012Z3Z0U4U/p1718654773178229*/}
        {/* <Banner /> */}
        <Card className={`${styles.card} text-white`}>
          <div className={`${styles.cardBody}`}>
          <h1 fluid="true" className={`${styles.jumbotron}`}>
              <Container fluid="lg" className={styles.cardContainer}>
                <Row className={styles.animatedHeadline}>
                  <Col>
                    <div className={`${styles.cardContent}`}>
                      <AnimatedHeadline before='Reciprocity is'
                        animated={[
                          'changing the culture of home',
                          'recognizing Indigenous land rights',
                          'a way to say thank you',
                          'knowing where you live'
                        ]}
                        style={{ color: '#6ec1e4', fontWeight: 'normal', fontSize: '100%' }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div><b>We are decolonizing our backyards, and our futures, together.</b></div>
                    <p>Find out what Reciprocity looks like for you:</p>
                  </Col>
                </Row>


                <Row className={`${styles.startBtnContainer} mt-5 mb-5`}>
                  <Col md={12} lg className="mt-3 mt-lg-0">
                    <Button className='p-3' variant='home' href='/im-a-homeowner' title="I'/m a homeowner" block="true">I'm a homeowner</Button>
                  </Col>
                  <Col md={12} lg className="mt-3 mt-lg-0">
                    <Button className='p-3' variant='home' href='/im-a-renter' block="true">I'm a renter</Button>
                  </Col>
                  <Col md={12} lg className="mt-3 mt-lg-0">
                    <Button className='p-3' variant='home' href='/im-a-business-owner' block="true">I'm a business owner</Button>
                  </Col>
                  <Col md={12} lg className="mt-3 mt-lg-0">
                    <Button className='p-3' variant='home' href='/im-something-else' block="true">I'm hosting an event</Button>
                  </Col>
                  {/* <Col md={12} lg className="mt-3 mt-lg-0">
                    <Button className='p-3' variant='home' href='/im-indigenous' block>I'm Indigenous</Button>
                  </Col> */}
                </Row>
                <Row>
                  <Col >
                    <Button variant='indigenous' className="border-0 mb-5" href='/im-indigenous'><strong>Part of an Indigenous Nation interested in Reciprocity Trusts? Click here.</strong></Button>
                  </Col>
                </Row>
              </Container>
            </h1>
          </div>
        </Card>

        <Container>
          <Row className={`${styles.mapRow}`}>
            <Col sm={12} md={6} className={`${styles.homeMapText}`}>
              <h3>
                <i className="fa fa-arrow-circle-right reciprocityLightBlueText"></i> We're building something revolutionary.
              </h3>
              <Link to="/reciprocity-map" target="_blank">
                Click on the map to learn more.
              </Link>
            </Col>
            <Col sm={12} md={6}>
              <Link to="/reciprocity-map" target="_blank">
                <Image src="/home-map.png" fluid="true" />
              </Link>
            </Col>
          </Row>
        </Container>

        <Container fluid="true">
          <Row className="reciprocityLightBlueBG text-center py-5">
            <Col>
              <h1 className="mb-5">
                Why we're excited about Reciprocity
              </h1>
              <Carousel>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/sarah-sammy.png"
                    alt="First slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      As new homeowners on southern Vancouver Island, we want to acknowledge that the lands we live on are unceded; Reciprocity allows us to participate in giving back and take a small tangible step toward decolonization.
                    </p>
                    <h3>
                      SARAH JOHNSON & SAMMY KENT
                    </h3>
                    <p>
                      Victoria, BC
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/nancy-john.png"
                    alt="Second slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      We've lived in beautiful places all across Canada and we've watched and waited too long for governments to repair relations with Indigenous communities. We still need our governments to do their job, but we have a role to play as individuals too.  Reciprocity is a great idea. It gives us a concrete way to recognize the Indigenous lands we get to call home. We are honoured to be part of it.
                    </p>
                    <h3>
                      NANCY & JOHN
                    </h3>
                    <p>
                      Kaslo, BC
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/kelsey-mech.png"
                    alt="Third slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      The moment I heard about Reciprocity it resonated with me. We, as settlers, live rent-free on Indigenous land. Reciprocity moves beyond lip service and takes noticeable action towards recognizing Indigenous land.
                    </p>
                    <h3>
                      KELSEY MECH
                    </h3>
                    <p>
                      Salt Spring Island, BC
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/jenna-stoner.png"
                    alt="Fourth slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      I’m excited to join Reciprocity because I want to be part of a bold and brave movement that encourages difficult conversations and challenges people to take meaningful action towards decolonization and acknowledgement of Indigenous rights.
                    </p>
                    <h3>
                      JENNA STONER
                    </h3>
                    <p>
                      Squamish, BC
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/stephen-hui.png"
                    alt="Fifth slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      I want to do something more. Acknowledging that we live in Indigenous territories rings hollow without action. Like many people I know, I want to make a real contribution to decolonization. A tangible way to support First Nations, whose lands we occupy, could be a step in the right direction. Come with me?</p>
                    <h3>
                      STEPHEN HUI
                    </h3>
                    <p>
                      Vancouver, BC
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/pierre.png"
                    alt="Sixth slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      For me, Reciprocity is an important way to publicly show support for change where we live and make recognition of Indigenous rights real and visible to everyone.</p>
                    <h3>
                      PIERRE
                    </h3>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className='mb-3'
                    src="/tom_goodman.png"
                    alt="Seventh slide"
                  />
                  <div className='carouselText'>
                    <p className='px-5'>
                      As a Realtor, my work is directly tied with sale and profit from traditional Indigenous lands. As a sign of my commitment of solidarity with First Nations, I am pledging my support to making Reciprocity payments and giving back to the Indigenous peoples who have shared these lands and resources. I hope that this gesture will help and I encourage others to do the same.</p>
                    <h3>
                      TOM
                    </h3>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withRouter(Home)

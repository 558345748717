import React from "react";

import { Alert, Card, Form, Col, Row, Button, Spinner } from "react-bootstrap";

import {
  ElementsConsumer,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PropTypes from "prop-types";

import config from "../../config";
import {
  getSession,
  saveSession,
  userLogin,
  userRegister,
  getUserFromToken,
  postOrder,
  updateOrder,
} from "../../utils";

import styles from "./CreditCardForm.module.css";

const elementOptions = {
  classes: {
    base: "form-control",
  },
  style: {
    base: {
      fontSize: "16px",
      fontWeight: 400,
      // Ignore the stripe warning for this
      lineHeight: 1.5,
    },
  },
};

class CardElementForm extends React.Component {
  constructor(props) {
    super(props);

    const registration = this.props.order.registration;
    let region = '';
    if (registration.municipality && registration.municipality.regionId) {
      region = registration.municipality.regionId;
    } else if (registration.ruralArea && registration.ruralArea.regionId) {
      region = registration.ruralArea.regionId;
    }
    const session = getSession();

    this.state = {
      name: "", //`${ registration.firstName } ${ registration.lastName }`,
      postalCode: registration.postalCode || "",
      phone: registration.phone || "",
      email: "",
      password: "",
      region: region,

      session: session,

      paymentIntent: null,

      loading: false,
      errorMsg: "",
    };
  }

  getUserSession = async (email, password) => {
    let token = null;
    let session = null;
    let errorMsg = null;

    // Try a new user first
    try {
      token = await userRegister(this.state.email, this.state.password);
    } catch (error) {
      if (error.message) {
        if (!error.message.includes("already registered")) {
          errorMsg = error.message;
        }
      } else {
        errorMsg = "Unknown error registering new user";
      }
    }

    if (!token && !errorMsg) {
      // Try an exsting user
      try {
        token = await userLogin(this.state.email, this.state.password);
      } catch (error) {
        errorMsg = error.message || "Unknown error logging in user";
      }
    }

    console.log("Check user token", token);
    if (token) {
      // Save to session
      const response = await getUserFromToken(token.token);
      console.log(response);
      session = saveSession(response.id, response.email, token.token);
      console.log("Saved user session");
    }
    return { session: session, error: errorMsg };
  };

  // Stripe amounts are in the currency's smallest unit
  getStripeAmount = (total, currency) => {
    const zeroDecimalCurrencies = [
      "bif",
      "clp",
      "djf",
      "gnf",
      "jpy",
      "kmf",
      "krw",
      "mga",
      "pyg",
      "rwf",
      "ugx",
      "vnd",
      "vuv",
      "xaf",
      "xof",
      "xpf",
    ];
    if (zeroDecimalCurrencies.includes(currency)) return total;
    return total * 100;
  };

  setErrorMsg = (errorMsg) => {
    this.setState({
      loading: false,
      errorMsg: errorMsg || "",
    });
  };

  checkIfRegionCollectingPayments() {
    const registration = this.props?.order?.registration || null;
    if (registration) {
      let region = null;
      if (registration.ruralArea ) {
        region = registration.ruralArea.regionId;
      } else if (registration.municipality) {
        region = registration.municipality.regionId;
      }
      // region 5 == south island trust
      if (region && region === "rgn_5") {
        return true;
      }
    }
    return false;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      loading: true,
      errorMsg: "",
    });

    console.log("SUB:", this.state);
    console.log("result.session:", this.state.session);

    this.setState({ session: { userEmail: this.state.email } });

    this.props.order.email = this.state.email;
    // only collect payments if the registration is part of South Island Trust (rgn_5)
    if (this.checkIfRegionCollectingPayments()) {
      if (!this.state.session) {
        // Use the email and password to try and login or create a user and generate a session
        const result = await this.getUserSession(this.state.email, this.state.password);
        if (result.error) {
          this.setErrorMsg(result.error);
          return;
        }
        this.setState({ session: result.session });
      }

      let order = this.props.order;
      order.amount = this.getStripeAmount(order.total, order.currency);
      let paymentIntentVariable;
      if (!this.state.paymentIntent) {
        // Get the intent from stripe
        console.log("Posting order:", order);
        const result = await postOrder({
          amount: order.amount,
          currency: order.currency,
          description: order.description,
          receipt_email: order.email,
          metadata: {
            userId: this.state.session.userId,
          },
        });
        console.log("---");
        console.log(result);

        if (result.err) {
          this.setErrorMsg(`Order error: ${result.err.code} ${result.err.param}`);
          return;
        }

        if (!result?.data || !result.data.client_secret) {
          this.setErrorMsg("Order error: failed to get client secret", result.data);
          return;
        }
        console.log("paymentIntent", result.data);
        paymentIntentVariable = result.data;
        this.setState({ paymentIntent: result.data });
      } else {
        console.log("no session");
        // Look for changes in the order
        let properties = {};
        properties.receipt_email = order.email;
        if (order.amount !== this.state.paymentIntent.amount) properties.amount = order.amount;
        if (order.currency !== this.state.paymentIntent.currency) properties.currency = order.currency;
        if (order.description !== this.state.paymentIntent.description) properties.description = order.description;
        // Update intent if necessary
        if (Object.keys(properties).length > 0) {
          const result = await updateOrder(this.state.paymentIntent.id, properties);

          if (result.err) {
            this.setErrorMsg(`Order error: ${result.err.code} ${result.err.param}`);
            return;
          }

          if (!result.paymentIntent) {
            this.setErrorMsg("Order error: failed to update");
            return;
          }

          console.log("paymentIntent", result.paymentIntent);
          paymentIntentVariable = result.paymentIntent;
          this.setState({ paymentIntent: result.paymentIntent });
        }
      }
      console.log("t:", this.state);
      // Send the card info to stripe
      const result = await this.props.stripe.confirmCardPayment(paymentIntentVariable.client_secret, {
        payment_method: {
          card: this.props.elements.getElement(CardNumberElement),
          billing_details: {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone || null,
            address: {
              postal_code: this.state.postalCode,
            },
          },
        },
      });

      console.log("result:", result);
      if (result.error) {
        this.setErrorMsg(result.error.message || result.error.code || "Unknown error. Please try again.");
      } else {
        console.log("Submitting to props1:");
        console.log(this.state);
        this.props.submit({
          name: this.state.name,
          phone: this.state.phone,
          postalCode: this.state.postalCode,
          paymentIntent: paymentIntentVariable,
        });
        this.setErrorMsg();
      }
    } else {
      console.log("Submitting to props2:");
      console.log(this.state);
      this.props.submit({
        name: this.state.name,
        phone: this.state.phone,
        postalCode: this.state.postalCode,
        pledgeOnly: true,
      });
    }
    this.setErrorMsg();
  };

  handleChange = (event) => {
    if (event.target.name) {
      this.setState({ [event.currentTarget.name]: event.currentTarget.value });
    }
  };

  render = () => {
    const { submit, ...rest } = this.props;

    return (
      <Card {...rest}>
        <Card.Header>{this.state.region === 'rgn_5' ? 'Join Reciprocity Today' : 'Pledge to join Reciprocity:'}</Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Name *</Form.Label>
                <Form.Control required name="name" value={this.state.name} onChange={this.handleChange} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="cardElement">
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  required
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Row>
            {this.checkIfRegionCollectingPayments() && (
              <>
                <Row>
                  <Form.Group as={Col} controlId="cardElement">
                    <Form.Label>Credit Card Number *</Form.Label>
                    <CardNumberElement required options={elementOptions} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="expiryDate">
                    <Form.Label>Expiry Date *</Form.Label>
                    <CardExpiryElement options={elementOptions} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="cvc">
                    <Form.Label>Security Code *</Form.Label>
                    <CardCvcElement options={elementOptions} />
                  </Form.Group>
                </Row>
              </>
            )}
            <Row>
              <Form.Group as={Col} controlId="postalCode">
                <Form.Label>Postal Code *</Form.Label>
                <Form.Control required name="postalCode" value={this.state.postalCode} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control name="phone" value={this.state.phone} onChange={this.handleChange} />
              </Form.Group>
            </Row>
            {/* {!this.state.session && this.checkIfRegionCollectingPayments() && (
              <div>
                <hr className={`${styles.hr}`} />
                <div className={styles.noSession}>Sign up or log in to your account:</div>
                <Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>Email Address *</Form.Label>
                    <Form.Control
                      required
                      name="email"
                      autoComplete="email"
                      readOnly
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="password">
                    <Form.Label>Password *</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Row>
              </div>
            )} */}
            <div className={styles.requiredTag}>* Required field</div>
            {this.state.errorMsg && <Alert variant="danger">{this.state.errorMsg}</Alert>}
            <Button type="submit" disabled={!this.props.stripe || !this.props.elements}>
              {this.state.loading && (
                <span>
                  <Spinner as="span" animation="grow" size="sm" /> Processing Your Payment...
                </span>
              )}
              {!this.state.loading && <span>{this.state.region === 'rgn_5' ? 'Make My Reciprocity Payment' : 'Pledge Now:'}</span>}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    );
  };
}

const stripePromise = loadStripe(config.stripe_publishable_key);

const CreditCardForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => <CardElementForm elements={elements} stripe={stripe} {...props} />}
      </ElementsConsumer>
    </Elements>
  );
};

CreditCardForm.propTypes = {
  order: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CreditCardForm;

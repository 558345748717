import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './OurTeam.module.css'
import { default as withRouter } from "../../components/WithRouter/withRouter";

class OurTeam extends Component {
  render() {
    return (
      <div>
        <div className="smallContainer">
          <section className="marginBottom100">
            <h1 className="text-center mb-5">
              <span className="reciprocityGreenText">o</span>ur story<span className="reciprocityLightBlueText">.</span>
            </h1>
            <p>
              <b>
                Reciprocity is the product of hundreds of conversations over coffee, bannock, moose stew and jarred salmon.
              </b>
            </p>
            <p className="text-muted font-thin">
              This project was initiated by a small group of B.C. based social scientists and ecologists that have collectively worked with Indigenous communities for over 35 years. It's through working with Indigenous communities that we learnt the importance of reciprocity. It's based on that work that we hope Reciprocity will provide new ways to recognize our relationships as homeowners, business owners, and relative newcomers, on Indigenous land.
            </p>
            <p className="text-muted font-thin">
              We all sit down at the table with colonialism every day, whether we recognize it or not. We know that reciprocity is at the heart of historic treaties and relationships that we are all part of. We know that our colonial past has had too little reciprocity, and our colonial present needs a lot more.
            </p>
            <p className="text-muted font-thin">
              As renters, homeowners and business owners, we are privileged to live, work and own property on Indigenous lands. After 150+ years, we think it's time to start decolonizing our backyards.
            </p>
            <p>
              We want to decolonize our backyards, and our futures, <strong>together.</strong>
            </p>
          </section>
          <section className="marginBottom100 text-center">
            <h1 className="text-center reciprocityPurpleText mb-5">
              meet our team
            </h1>
            <h3 className="text-center mb-5">
              <span className="reciprocityGreenText">S</span>outh Island Indigenous Reciprocity Trustees<span className="reciprocityLightBlueText">.</span>
            </h3>
            <p className='font-thin'>
              The inaugural South Island Indigenous Reciprocity Trust names all ten Nations with interests on Southern Vancouver Island as beneficiaries: T’Sou-ke Nation, the Lək̓ʷəŋən people of the Esquimalt and Songhees Nations, the WSÁNEĆ communities of W̱JOȽEȽP (Tsartlip), SȾÁUTW̱ (Tsawout) and W̱SIḴEM (Tseycum), MÁLEXEȽ (Malahat) and BOḰEĆEN (Pauquachin) First Nations as well as the Sc'ianew (Beecher Bay) First Nation and Pacheedaht First Nation.  Each beneficiary Nation can appoint a Trustee at any time.
            </p>
            <Row className='marginBottom100 mx-5'>
              <Col>
                <img
                  className='mb-3'
                  src='/malahat.png'
                  alt='malahat'
                />
                <h5>Josh Handysides</h5>
                <div>
                  Interim MÁLEXEȽ (Malahat) Nation Trustee
                </div>
              </Col>
              <Col >
                <img
                  className='mb-3'
                  src='/tsartlip.png'
                  alt='tsartlip'
                />
                <h5>Jeanine Cooper</h5>
                <div>
                  W̱JOȽEȽP (Tsartlip) Nation Trustee
                </div>
              </Col>
              <Col >
                <img
                  className='mb-3'
                  src='/tsawout.png'
                  alt='tsawout'
                />
                <h5>Abraham Pelkey</h5>
                <div>
                  SȾÁUTW̱ (Tsawout) Nation Trustee
                </div>
              </Col>
            </Row>
          </section>
          <section className="marginBottom100">
            <h3 className="text-center mb-5">
              <span className="reciprocityGreenText">r</span>eciprocity trusts society<span className="reciprocityLightBlueText">.</span>
            </h3>
            <p className="font-thin">
              The Reciprocity Trusts Society (Reciprocity Trusts) is a registered non-profit organisation dedicated to changing the culture of private property ownership on Indigenous lands.  The role of Reciprocity Trusts is to establish and provide administrative and communications support for regional, Indigenous-led Reciprocity Trusts.
            </p>
            <p className="font-thin">
              Reciprocity Trusts Society has been lovingly incubated and brought to life with financial support, staff time and resources from Reciprocity Research. Learn more about our extended family at <a href="https://reciprocityconnects.ca/about-us/" target="_blank" rel="noopener noreferrer">reciprocityconnects.ca</a>.

              {/* We are a small team of BC based social scientists and ecologists, passionate about doing good work that returns financial, social, cultural and ecological wealth to Indigenous Nations and helps change the culture of home. Reciprocity Trusts Society has been lovingly incubated and brought to life with financial support, staff time and resources from Reciprocity Research. Learn more about our extended family at <a href="https://reciprocityconnects.ca/about-us/" target="_blank" rel="noopener noreferrer">reciprocityconnects.ca</a>. */}
            </p>
          </section>
        </div>
        <Container className="marginBottom150">
          <Row className="mb-5">
          <Col md>
              <img
                className='mb-3'
                src="/sarah-reid.png"
                alt="sarah-reid"
              />
              <h3>
                Sarah Reid
              </h3>
              <h3 className="font-thin">
                Executive Director
              </h3>
              <p className="text-muted">
                Sarah Reid (MA) has worked with Indigenous communities in BC and Alberta for 8+ years, supporting the design and implementation of community-based socio-economic baseline and impact assessment studies; stewardship, land use and strategic planning processes.
              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:sarah@reciprocityconnects.ca" target="_blank" rel="noopener noreferrer">sarah@reciprocityconnects.ca</a>
              </p>
              <p className="text-muted">
                <i className="fa fa-phone reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="tel:778.606.2511;ext=1">778.606.2511 ext. 1</a>
              </p>
            </Col>
            <Col md>
              <img
                className='mb-3'
                src="/craig-candler.png"
                alt="craig-candler"
              />
              <h3>
                Craig Candler
              </h3>
              <h3 className="font-thin">
                Board Secretary, Reciprocity Trusts Society & Founder + CEO, Reciprocity Research
              </h3>
              <p className="text-muted">
                Craig Candler (PhD) is a cultural anthropologist and Founder and CEO of Reciprocity Research Inc. Based in Victoria, British Columbia, Craig has more than 25 years of experience in environmental, regulatory, and indigenous rights processes. He helped found the Firelight Group and works with communities to produce strong, respected, and respectful research at the applied intersection of ecological, medical, and legal anthropology.
              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:craig@reciprocityconnects.ca" target="_blank" rel="noopener noreferrer">craig@reciprocityconnects.ca</a>
              </p>
              <p className="text-muted">
                <i className="fa fa-phone reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="tel:778.606.2511;ext=2">778.606.2511 ext. 2</a>
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
          <Col md>
              <img
                className='mb-3'
                src="/naomi-devine.png"
                alt="naomi-devine"
              />
              <h3>
                Naomi Devine
              </h3>
              <h3 className="font-thin">
                Storyteller, Strategic Advisor & Board Chair, Reciprocity Trusts Society
              </h3>
              <p className="text-muted">
                Naomi Devine is a sustainability policy and communications expert, trained in human-centered design thinking, and is a member of the Graphic Designers of Canada (GDC). For over a decade she has advised governments and worked with universities, local governments, and civil society organisations on sustainability and climate policy. Visual communications and storytelling are what she employs to assist communities and organisations in crafting persuasive, effective, and long-lasting moves toward a more equitable future.
              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:naomi@reciprocityconnects.ca" target="_blank" rel="noopener noreferrer">naomi@reciprocityconnects.ca</a>
              </p>
            </Col>
            <Col md>
              <img
                className='mb-3'
                src="/sammy-kent.png"
                alt="sammy-kent"
              />
              <h3>
                Sammy Kent
              </h3>
              <h3 className='font-thin'>
                Board Vice Chair, Reciprocity Trusts Society & Cultural Ecologist and Lead Restoration Agrologist, Reciprocity Restoration
              </h3>
              <p className="text-muted">
                Sammy Kent (BSc, PAg) is a restoration professional and Indigenous entrepreneur with almost 10 years of experience working directly on native plant and community-based restoration projects. Sammy has extensive experience working with Indigenous communities on knowledge and use studies, environmental assessments, ecological and cultural use mapping, and community-driven projects supporting Indigenous knowledge, sensitive ecosystems, and restoration. Sammy is a member in good standing with the BC Institute of Agrology, a Certified Permaculture Designer, Organic Horticulture Specialist, and Arborist Technician.              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:sammy@reciprocityconnects.ca" target="_blank" rel="noopener noreferrer">sammy@reciprocityconnects.ca</a>
              </p>
            </Col>
            {/* <Col md>
              <img
                className='mb-3'
                src="/dexter-robson.png"
                alt="dexter-robson"
              />
              <h3>
                Dexter Robson
              </h3>
              <h3 className="font-thin">
                Cultural Geographer, Reciprocity Research
              </h3>
              <p className="text-muted">
                Dexter Robson (MA) is a cultural geographer at Reciprocity whose main focus is on participatory research, mapping and GIS. His most recent work looks into evaluating online mapping technologies for how they facilitate Indigenous self-determination at the community level.
              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:dexter@reciprocityconnects.ca">dexter@reciprocityconnects.ca</a>
              </p>
            </Col>
            <Col md>
              <img
                className='mb-3'
                src="/ash-morris.png"
                alt="ash-morris"
              />
              <h3>
                Ash Morris
              </h3>
              <h3 className="font-thin">
                Junior Cultural Ecologist, Reciprocity Research
              </h3>
              <p className="text-muted">
                Ash Morris (BSc, BIT) is a cultural ecologist at Reciprocity providing support for design and implementation of research projects, and environment impact assessment. Originally from Australia, Ash moved to Canada to continue her work with Indigenous Nations. Her previous work includes developing and implementing community-based monitoring programs and research for Indigenous ecological knowledge studies.
              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:ash@reciprocityconnects.ca">ash@reciprocityconnects.ca</a>
              </p>
            </Col> */}
          </Row>
          <Row className="mb-5">
            <Col md>
              <img
                className='mb-3'
                src="/carolyn-whittaker.png"
                alt="carolyn-whittaker"
              />
              <h3>
                Carolyn Whittaker
              </h3>
              <h3 className="font-thin">
                Board Treasurer, Reciprocity Trusts Society
              </h3>
              <p className="text-muted">
              Ecologist and science communicator, Carolyn Whittaker is a producer for Handful of Films. As an entrepreneur, ecology researcher and film producer, Carolyn develops stories, builds teams and finds ways for science to have impact particularly to further social justice and conservation. Carolyn’s current work with Handful of Films builds on ten years’ experience as a Founder and Director of Ecology at the Firelight Group where she focused on Indigenous rights and ecological knowledge studies. Before Co-Founding Firelight Carolyn led teams focused on biodiversity, science and media communication for Parks Canada, University of Alberta, University of British Columbia and Rutgers University.
              </p>
              {/* <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:naomi@reciprocityconnects.ca">eleni@reciprocityconnects.ca</a>
              </p> */}
            </Col>
            <Col md>
              {/* <img
                className='mb-3'
                src="/elani-mcgowans.png"
                alt="elani-mcgowans"
              />
              <h3>
                Elani McGowans
              </h3>
              <h3 className="font-thin">
                Junior Cultural Ecologist, Reciprocity Research
              </h3>
              <p className="text-muted">
                Eleni McGowans (BSc) is a junior cultural ecologist at Reciprocity who supports and facilitates community-based ecological and social science research with Indigenous communities. Eleni has a strong background in environmental science and is working toward her master’s degree in environmental practice.
              </p>
              <p className="text-muted">
                <i className="fa fa-envelope reciprocityPurpleText"></i>
                &nbsp;
                <a className={`${styles.anchor}`} href="mailto:naomi@reciprocityconnects.ca">eleni@reciprocityconnects.ca</a> 
              </p>*/}
            </Col>
            
          </Row>
          <Row className="mb-5">
            
            <Col />
          </Row>
          {/* <Row className="mb-5">
            
           
          </Row>
          <Row className="mb-5">
            
            <Col md>
            </Col>
          </Row> */}
        </Container>
      </div>
    )
  }
}
export default withRouter(OurTeam)

import React from 'react';

import { NumericFormat } from 'react-number-format';

import PropTypes from 'prop-types';

class CurrencyInput extends React.Component {
  handleValueChange = values => {
    this.props.onChange(this.props.name, values.floatValue || 0);
  }

  handleOnClick = event => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    if (event && event.target) {
      event.target.focus();
      event.target.select();
    }
  }

  render = () => {
    const { onChange, name, ...props } = this.props;

    return (
      <NumericFormat className='text-right' thousandSeparator={ true } prefix={ '$' } decimalScale={ 0 } fixedDecimalScale={ true }
        allowNegative={ false } onValueChange={ this.handleValueChange }
        { ...props } onClick={ this.handleOnClick }
      />
    );
  }
};

CurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CurrencyInput;

import React from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import moment from 'moment';

import './Footer.css';

import FooterNewsletter from '../FooterNewsletter/FooterNewsletter'

import { getSession } from '../../utils'

import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const today = moment();
const currentYear = today.year();

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      session: null,
    };
  }

  async componentDidMount() {
    this.setState({
      session: getSession()
    })
  }

  render() {
    return (
      <div>
        {!this.state.session &&
          <FooterNewsletter />
        }
        <Navbar bg="dark" variant="dark">
          <Nav className='m-auto'>
            <small className="text-muted m-auto pr-2">Reciprocity Trusts is a not-for-profit initiative of Reciprocity Research Inc.,
              a social enterprise found at&nbsp;<a href="https://reciprocityconnects.ca/">ReciprocityConnects.ca</a>. &copy;&nbsp;{currentYear}
            </small>
            <Nav.Link href="https://www.facebook.com/reciprocityconnects" target="_blank">
              <FacebookIcon className="footer-social-media"></FacebookIcon>
            </Nav.Link>
            <Nav.Link href="https://twitter.com/reciprocity_ca" target="_blank">
              <TwitterIcon className="footer-social-media"></TwitterIcon>
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/reciprocityconnects/" target="_blank">
              <InstagramIcon className="footer-social-media"></InstagramIcon>
            </Nav.Link>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default Footer;

import React, { Component } from 'react'
import styles from './ReciprocityNations.module.css'
import Container from 'react-bootstrap/Container'
import { default as withRouter } from "../../components/WithRouter/withRouter";

class ReciprocityNations extends Component {
  render() {
    return (
      <div>
        <Container>
          <section className="marginBottom100">
            <h1 className="text-center">
              <span className="reciprocityGreenText">r</span>eciprocity<span className="reciprocityLightBlueText">.</span>
            </h1>
            <h1 className="text-center">
              <span className="reciprocityGreenText">n</span>ations<span className="reciprocityLightBlueText">.</span>
            </h1>
          </section>

          <section className="marginBottom50">
            <p className="mb-3">
              Reciprocity and First Nations across BC are working together to set up Reciprocity Trusts in your area to help you <strong>decolonize your backyard.</strong>
            </p>
            <p className="text-muted font-weight-light">
              The Regional Reciprocity Trust areas are based on Regional District boundaries. Within each trust area, there exists a diverse group of Indigenous nations and Reciprocity partners working towards making recognition of Indigenous land rights more meaningful. These relationships and partnerships are constantly evolving as we continue to work towards decolonization together.&nbsp;
              <strong className="font-weight-normal">Learn more about each of the Reciprocity Trust areas below.</strong>
            </p>

          </section>

          <section className="marginBottom50">
            <div className={`${styles.underline}`}>
              <p className='text-muted font-weight-light'>
                Currently, Reciprocity Trusts is focussed in Victoria and Southern Vancouver Island. The South Island Indigenous Reciprocity Trust is the first Reciprocity Trust to be established in the Spring of 2023. It is our vision that over time, regional Indigenous-led Reciprocity Trusts will extend across the country.
              </p>
            </div>
          </section>
        </Container>

        <Container>
          <section className="mb-5">
            <p>
              <b className="d-flex">
                South Island Reciprocity Trust -&nbsp;<span className="text-muted font-weight-light font-italic">established Spring 2023</span>
              </b>
            </p>
            <p className="text-muted font-weight-light">
              The South Island Trust is located on the southern tip of Vancouver Island extending from what is now known as Port Renfrew, around the WSANEC Peninsula, and across the Southern Gulf Islands. This area includes  the traditional territories of the T’Sou-ke Nation, the Lək̓ʷəŋən speaking people of the Esquimalt and Songhees Nations, the WSÁNEĆ communities of W̱JOȽEȽP (Tsartlip), SȾÁUTW̱ (Tsawout) and W̱SIḴEM (Tseycum), MÁLEXEȽ (Malahat) and  BOḰEĆEN (Pauquachin) First Nations as well as the Sc'ianew (Beecher Bay) First Nation and Pacheedaht First Nation. The South Island Trust area is home to approximately 400,000 residents, with over 170,000 privately owned dwellings with an average value of approximately $760,800.
            </p>
          </section>

          <section className="mb-5">
            <p>
              <b className="d-flex">
                Lower Mainland Reciprocity Trust -&nbsp;<span className="text-muted font-weight-light font-italic">in progress</span>
              </b>
            </p>
            <p className="text-muted font-weight-light">
              The Lower Mainland Trust area surrounds the mouth of what is known today as the Fraser River, and follows the river on both sides eastward to Alouette Lake. This region was, and continues to be, a place where many First Nations convened, traded and thrived. It included traditional territories of the xʷməθkʷəy̓əm (Musqueam), Sḵwx̱wú7mesh (Squamish), səlilwətaɬ (Tsleil-Waututh), scəẁaθən (Tsawwassen), kʷikʷəƛ̓əm (Kwikwetlem), Máthxwi (Matsqui), Kwantlen, q̓ic̓əy̓ (Katzie) and Semiahmoo Nations. The Lower Mainland Trust area is home to approximately 2,500,000 residents, with over 900,000 privately owned dwellings with an average value of approximately $1,400,000.
            </p>
          </section>

          <section className="mb-5">
            <p>
              <b className="d-flex">
                Kootenay Reciprocity Trust -&nbsp;<span className="text-muted font-weight-light font-italic">in progress</span>
              </b>
            </p>
            <p className="text-muted font-weight-light">
              The Kootenay Trust area consists of the Central and East Kootenay Regional Districts. This area is mostly within the traditional territories of the Ktunaxa Nation and also includes portions of Secwépemc and Okanagan Nation territories. The Kootenay Trust area is home to over 100,000 residents, with over 50,000 privately owned dwellings with an average value of approximately $300,000.
            </p>
          </section>

          <section className="marginBottom100">
            <p>
              <b className="d-flex">
                Reciprocity Foundation Trust -&nbsp;<span className="text-muted font-weight-light font-italic">in progress</span>
              </b>
            </p>
            <p className="text-muted font-weight-light">
              The Foundation Trust functions to serve all First Nations in British Columbia who are outside of the existing Regional Reciprocity Trust areas. In these early days, the Reciprocity Foundation Trust area encompasses most of the province.  As Reciprocity and First Nations across B.C. continue to work together to build partnerships and establish Reciprocity Agreements, we envision the Foundation Trust will become smaller, and Regional Trust areas growing in numbers. Reciprocity payments made to the Reciprocity Foundation Trust are available to the First Nations who are not directly receiving Reciprocity Payments through the Regional Reciprocity Trusts.
            </p>
          </section>
        </Container>
      </div>
    )
  }
}
export default withRouter(ReciprocityNations)
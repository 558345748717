import React from 'react';
import { default as withRouter } from "../../components/WithRouter/withRouter";

import Registration, { RegistrationTypes } from '../Registration/Registration';

class ImIndigenous extends React.Component {
  onSuccess = async form => {
    this.props.history.push('/thankyou');
  }

  subtext() {
    return (
      <>
        <p>
          What would it look like if…thousands of households and small businesses started recognizing Indigenous land and Indigenous rights by saying thank you for where they live and work in a meaningful way?
        </p>
        <p>
          Reciprocity Trusts helps Indigenous Nations connect with property owners and small businesses who recognize that they live and work on, and benefit from, Indigenous land.
        </p>
        <p>
          With Reciprocity Trusts, Indigenous Nations who are most impacted by land privatization and alienation (especially in urban and recreational areas) receive the greatest benefit and the largest return of wealth, as well as a corresponding ability to enhance long-standing Indigenous traditions of generosity that recognize neighbours and relations.
        </p>
        <p><strong>
          Are you or your Nation interested in seeing Reciprocity Trusts in your area? We’d love to hear from you. Reach out to <a href="mailto:sarah@reciprocitytrusts.ca">sarah@reciprocitytrusts.ca</a> or fill out the form below and we’ll get back to you soon.
        </strong></p>
      </>
    )
  }

  render() {
    return (
      <div>
        <h1 className={`marginTop100 marginBottom25 text-center`}>
          <span className="reciprocityGreenText">i</span>'m indigenous<span className="reciprocityLightBlueText">.</span>
        </h1>
        <h2 className={`marginBottom25 text-center`}>
          <span className="reciprocityGreenText">r</span>eciprocity for Indigenous Nations<span className="reciprocityLightBlueText">.</span>
        </h2>
        <Registration type={RegistrationTypes.indigenous} image="/reciprocity-other-logo.png" submitText="Submit"
          subText={this.subtext()}
          onSuccess={this.onSuccess}
        />
      </div>
    )
  }
}

export default withRouter(ImIndigenous);


import React from 'react';

import { Container, Card, Row, Col, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Registration, { RegistrationTypes } from '../Registration/Registration';

import RegionSummary from '../../components/RegionSummary/RegionSummary';
import PaymentSelectButton from '../../components/PaymentSelectButton/PaymentSelectButton';
import CurrencyText from '../../components/CurrencyText/CurrencyText';
import CreditCardForm from '../../components/CreditCardForm/CreditCardForm';
import Contact from '../../components/Contact/Contact';
import Success from '../Success/Success';
import WhatsNextPopover from '../../components/WhatsNextPopover/WhatsNextPopover';

import { getRegionFromMunicipality, postPledge, postPayment, getRegionByRuralId } from '../../utils';

import moment from 'moment';

import styles from './ImAHomeowner.module.css'

const today = moment();
const currentYear = today.year();

class ImAHomeowner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "register",

      registration: null,

      region: null,

      term: "yearly",
      monthlyPayment: 10,
      yearlyPayment: 100,
      address: '',
      publicMap: true,

      pledgeOnly: true,

      description: '',

      payForPrevious: false,
      previousYears: 2,
      subscribeAnnually: false,
      years: 0,
      total: 0,
      currency: 'cad',

      creditCardPayment: null,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.mode !== prevState.mode) {
      window.scrollTo(0, 0);
    }
  }

  setActivePaymentType = type => {
    this.setState({
      term: type
    }, () => {
      this.calculateTotals();
    });
  }

  calculatePayments = async form => {
    // Note: form.municipality is an object
    // The municipality will give us the region
    let region;
    if (form.rural && form.ruralArea) {
      region = await getRegionByRuralId(form.ruralArea.name);
    } else if (form.municipality) {
      region = await getRegionFromMunicipality(form.municipality);
    }    
    // outside of any region
    if (!region) {
      region = {
        id: null,
        key: 'rft'
      }
    }

    let isPledgeOnly = true;
    if (region && region.id === 'rgn_5'){
      isPledgeOnly = false;
    }

    // Calculate payment based on municipal rates
    const payment = form.value * (form.rural ? form.ruralArea.rural_rate : form.municipality.mun_rate) / 100000;

    this.setState({
      mode: "calculate",
      registration: form,
      address: form.address,
      region: region,
      pledgeOnly: isPledgeOnly,
      monthlyPayment: Math.ceil(payment),
      yearlyPayment: Math.ceil(payment * 12),
    }, () => {
      this.calculateTotals();
    });
  }

  handlePayForPreviousChange = () => {
    this.setState({
      payForPrevious: !this.state.payForPrevious
    }, () => {
      this.calculateTotals();
    });
  }

  handleSubscribeAnnuallyChange = () => {
    this.setState({
      subscribeAnnually: !this.state.subscribeAnnually
    }, () => {
      this.calculateTotals();
    });
  }

  handlePreviousYearsChange = event => {
    this.setState({
      previousYears: parseInt(event.target.value)
    }, () => {
      this.calculateTotals();
    });
  }

  calculateTotals = () => {
    let total = 0;
    let description = '';
    let years = 0;

    if (this.state.term === 'monthly') {
      total = this.state.monthlyPayment;
      description = 'Monthly subscription payment.';
    } else if (this.state.term === 'yearly') {
      total = this.state.yearlyPayment;
      description = `Payment for ${currentYear}.`;
      years = 1;
      if (this.state.payForPrevious) {
        total += this.state.yearlyPayment * this.state.previousYears;
        description += ` Payment for ${this.state.previousYears} previous year${this.state.previousYears > 1 ? 's' : ''}.`;
        years += this.state.previousYears;
      }
      if (this.state.subscribeAnnually) {
        description += ' Yearly subscription.';
      }
    }

    this.setState({
      total: total,
      description: description,
      years: years
    });
  }

  handlePublicMapChange = () => this.setState({ publicMap: !this.state.publicMap });

  handleCreditCardSubmit = async creditCardPayment => {
    console.log("Setting state:")
    console.log(creditCardPayment);
    this.setState({
      creditCardPayment: creditCardPayment
    }, async () => {
      const { mode, ...payment } = this.state;
      console.log("State");
      console.log(this.state)
      payment['name'] = creditCardPayment?.name || '';
      payment['postalCode']= creditCardPayment?.postalCode || '';
      payment['phone']= creditCardPayment?.phone || '';
      console.log("payment done");
      console.log(creditCardPayment);
      if (!creditCardPayment?.pledgeOnly) {
        // In prod, creditCardPayment.paymentIntent.status === 'requires_payment_method'
        // TODO FIX
        // if (creditCardPayment?.status === 'succeeded') {
          try {
            console.log("Posting payment");
            console.log(payment);
            await postPayment(payment);
            this.setState({ mode: "success" });
          } catch (error) {
            console.log("Posting payment error:");
            console.log(error);
            this.setState({
              mode: "error",
              error: error.message
            });
          }
        // } else {
        //   console.log("Payment wasn't successful.");
        //   console.log(creditCardPayment);
        //   this.setState({
        //     mode: "error",
        //     error: `Payment status: ${creditCardPayment.paymentIntent?.status}`
        //   });
        // }
      }
      try {
        console.log("Posting pledge");
        await postPledge(payment);
        this.setState({ mode: "success" });
      } catch (error) {
        console.log("Posting pledge error");
        console.log(error);
        this.setState({
          mode: "error",
          error: `Pledge status: ${error.message}`
        });
      }
    });
  }

  render() {
    const { mode, region, term, monthlyPayment, yearlyPayment, address, payForPrevious, previousYears, publicMap, subscribeAnnually, total } = { ...this.state };

    return (
      <div>
        {mode !== 'success' &&
          <h1 className={`marginTop100 marginBottom25 text-center`}>
            <span className="reciprocityGreenText">i</span>'m a homeowner<span className="reciprocityLightBlueText">.</span>
          </h1>
        }
        {mode === 'register' &&
          <Registration type={RegistrationTypes.homeowner} image="/reciprocity-homeowner-logo.png" submitText="Calculate my reciprocity payment"
            subText={`If you own property in B.C., type in the address of the home you'd like to make part of Reciprocity below and we'll calculate your recommended ${currentYear} Reciprocity Payment. It's about 1% of your property taxes per month (12% annually).`}
            onSuccess={this.calculatePayments}
          />
        }
        {mode === 'success' &&
          <Success pledgeOnly={this.state.pledgeOnly} type='homeowner' years={this.state.years} />
        }
        {mode === 'error' &&
          <Alert variant='danger'>{this.state.error}</Alert>
        }
        {mode === 'calculate' &&
          <Container>
            <Row>
              <RegionSummary region={region ? region : {key: 'rtf'}} />
            </Row>
            <Row>
              {region.id === 'rgn_5' ?
                <p>
                  <strong>We’ve calculated your recommended Reciprocity Payment for {currentYear} at a rate of 1% of your annual property taxes per month (12% of annual property tax). Select your preferred method of payment below.</strong>
                </p>
                :
                <p>
                  <strong>We've calculated your recommended Reciprocity Payment but we're not set up to take payments in your area yet. Sign up below and we'll get in touch once we're ready to go.</strong>
                </p>
              }
            </Row>
            <Row className={styles.buttonRow} xs={1} sm={2}>
              <Col>
                <PaymentSelectButton
                  isSelected={term === "yearly"}
                  type="yearly"
                  address={address}
                  paymentValue={yearlyPayment}
                  year={currentYear}
                  setActivePaymentType={this.setActivePaymentType} />
              </Col>
              <Col>
                <PaymentSelectButton
                  isSelected={term === "monthly"}
                  type="monthly"
                  address={address}
                  paymentValue={monthlyPayment}
                  year={currentYear}
                  setActivePaymentType={this.setActivePaymentType} />
              </Col>
            </Row>
            <Row xs={1} md={2}>
              {term === 'yearly' &&
                <Col>
                  <Card className={styles.wantMoreCard}>
                    <Card.Header>Want more?</Card.Header>
                    <Card.Body>
                      <Form>
                        <Row>
                          <Col xs={8}>
                            <Form.Check type='checkbox' id='payForPrevious' checked={payForPrevious} label="I'd like to pay for previous years"
                              onChange={this.handlePayForPreviousChange}
                            />
                          </Col>
                          <Col xs={2}>
                            <Form.Control type='number' min='1' value={previousYears} disabled={!payForPrevious}
                              onChange={this.handlePreviousYearsChange}
                            />
                          </Col>
                          <Col xs={2} className='text-right'>
                            years
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={9}>
                            <Form.Check type='checkbox' id='subscribeAnnually' checked={subscribeAnnually} label='Subscribe to an ongoing annual contribution'
                              onChange={this.handleSubscribeAnnuallyChange}
                            />
                          </Col>
                          <Col xs={3} className='text-right'>
                            <CurrencyText value={yearlyPayment} />/year
                          </Col>
                        </Row>
                        {subscribeAnnually &&
                          <Row>
                            <Col xs={12}>
                              <Card body className={styles.annualCard}>
                                * Your property value will change from year to year. Don't worry, we'll contact you if your recommended Reciprocity payment amount changes.
                              </Card>
                            </Col>
                          </Row>
                        }
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              }
              {term === 'monthly' &&
                <Col />
              }
              <Col>
                <Card className={styles.totalCard}>
                  <Card.Header>Total Payments Today:</Card.Header>
                  <Card.Body>
                    {term === 'yearly' &&
                      <Row>
                        <Col xs={8}>
                          Subscription Payment<br />
                          Billed annually
                        </Col>
                        <Col xs={4} className='text-right'>
                          <CurrencyText value={yearlyPayment} />
                        </Col>
                      </Row>
                    }
                    {term === 'yearly' && payForPrevious &&
                      <Row>
                        <Col xs={8}>
                          Payment for Previous Years
                        </Col>
                        <Col xs={4} className='text-right'>
                          <CurrencyText value={previousYears * yearlyPayment} />
                        </Col>
                      </Row>
                    }
                    {term === 'monthly' &&
                      <Row>
                        <Col xs={8}>
                          Subscription Payment<br />
                          Starting today, {today.format('MMM. D, yyyy')}<br />
                          Billed each month on the {today.format('Do')}
                        </Col>
                        <Col xs={4} className='text-right'>
                          <CurrencyText value={monthlyPayment} />/month
                        </Col>
                      </Row>
                    }
                    <hr className={`${styles.hr} my-1`} />
                    <Row className={styles.totalRow}>
                      <Col xs={8}>
                        Total
                      </Col>
                      <Col xs={4} className='text-right'>
                        <CurrencyText value={total} />
                      </Col>
                    </Row>
                    {term === 'yearly' && subscribeAnnually &&
                      <Row className={styles.subscriptionRow}>
                        <Col xs={8}>
                          Subscription Payment<br />
                          Billed annually on {today.format('MMM Do')}
                        </Col>
                        <Col xs={4} className='text-right'>
                          <CurrencyText value={yearlyPayment} />/year
                        </Col>
                      </Row>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={0} md={2} lg={3} />
              <Col xs={12} md={8} lg={6}>
                <Form.Check className={styles.publicMap} type='checkbox' id='publicMap' checked={publicMap}
                  label={
                    <div>
                      <span>I'd like to be recognized on the </span>
                      <Link to={'/reciprocity-map'} target='_blank'>Reciprocity Map.</Link>
                      <span> Please note: your name or address will never be shown on the map.</span>
                    </div>
                  }
                  onChange={this.handlePublicMapChange}
                />
                <div className='d-flex justify-content-center'>
                  <WhatsNextPopover />
                </div>
                <CreditCardForm className={styles.creditCardForm} order={this.state} submit={this.handleCreditCardSubmit} />
                <div className='text-justify'>
                  <small><i>Reciprocity respects your privacy. By clicking {this.state.pledgeOnly ? "'PLEDGE NOW'" : "'MAKE MY RECIPROCITY PAYMENT'"} you consent to share your information with Reciprocity and receive updates, including when we are up and running in your area, by email, text and/or phone. You can unsubscribe at any time.</i></small>
                </div>
                <Contact type='problem' className='text-center pt-4 pb-4' />
              </Col>
              <Col xs={0} md={2} lg={3} />
            </Row>
          </Container >
        }
      </div>
    )
  }
}

export default ImAHomeowner;

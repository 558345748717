import React from 'react';

import { NumericFormat } from 'react-number-format';

import PropTypes from 'prop-types';

class CurrencyText extends React.Component {
  render = () => {
    const { style, ...props } = this.props;

    return (
      <NumericFormat thousandSeparator={ true } prefix={ '$' } decimalScale={ 0 } fixedDecimalScale={ true }
        allowNegative={ false } displayType={ 'text' } renderText={ value => <span className='text-right' style={ style || {} }>{ value }</span> }
        { ...props }
      />
    );
  }
};

CurrencyText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CurrencyText;

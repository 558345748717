import React from 'react';

import { Container, Modal } from 'react-bootstrap';

import PropTypes from 'prop-types';

class Assessment extends React.Component {
  render = () => {
    return <Modal show={ this.props.show } onHide={ this.props.onHide } size='lg'>
      <Modal.Header closeButton className='py-2'>
        { this.props.rural ?
          <b>Find Your Rural Area Code:</b>
          :
          <b>Find Your Current Property Assessment Value:</b>
        }
      </Modal.Header>
      <Container>
        <p className='pt-3'>1. Check out <a target='_blank' rel='noopener noreferrer' href='https://www.bcassessment.ca/'>https://www.bcassessment.ca/</a></p>
        <p>2. Enter your address</p>
        <p><img src='/assessment_input.png' alt='where to enter your address' className='w-100'/></p>
        { this.props.rural ?
          <React.Fragment>
            <p>3. Find out your 3-digit Rural Area Code</p>
            <p><img src='/assessment_rural.png' alt='where to find your rural area code' className='w-100'/></p>
          </React.Fragment>
          :
          <React.Fragment>
            <p>3. Find out your Current Property Assessment Value</p>
            <p><img src='/assessment_value.png' alt='where to find your property value' className='w-100'/></p>
          </React.Fragment>
        }
        <p className='pb-2'>* Be sure this is the property you want to make part of Reciprocity</p>
      </Container>
    </Modal>
  }
}

Assessment.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  rural: PropTypes.bool,
};

export default Assessment;

import React from 'react';
import { Form } from 'react-bootstrap';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import PropTypes from 'prop-types';

class AddressLookup extends React.Component {
  constructor(props) {
    super(props);

    this.state = { address: props.address || '' };
  }

  handleChange = address => this.setState({ address });

  handleError = (status, clearSuggestions) => {
    console.error('Google Maps API error:', status);
    clearSuggestions();
  }

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(geoCode => this.props.onChange(address, geoCode[0]))
      .catch(error => console.error('geocodeByAddress error:', error));
  };

  render() {
    return (
      // searchOptions: Limit to addresses in BC
      <PlacesAutocomplete highlightFirstSuggestion value={ this.state.address } onChange={ this.handleChange }
        onSelect={ this.handleSelect } onError={ this.handleError }
        searchOptions={{ bounds: { north: 60, south: 48.3, west: -139.06, east: -114.03 },  types: ['address'] }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: 'relative' }}>
            <Form.Control { ...getInputProps() } value={ this.state.address } type='search' autoComplete='off'
              name={ this.props.name || 'address' } required={ this.props.required }
              placeholder={ this.props.placeholder || 'Start typing...' }
            />
            <div className={ `dropdown-menu${ suggestions.length ? ' show' : '' }` }>
              { loading && <div className='dropdown-item text-muted'>Loading...</div> }
              { suggestions.map(suggestion => {
                  return (
                    <div { ...getSuggestionItemProps(suggestion) }
                      className='dropdown-item' key={ suggestion.placeId }
                      style={{ backgroundColor: suggestion.active ? '#80bdff' : '#ffffff', cursor: 'pointer' }}
                    >
                      <span>{ suggestion.description }</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

AddressLookup.propTypes = {
  onChange: PropTypes.func.isRequired,
  address: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default AddressLookup;

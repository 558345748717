import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import WhatHappensNext from '../../components/WhatHappensNext/WhatHappensNext'
import { default as withRouter } from "../../components/WithRouter/withRouter";

class HowRWorks extends Component {
  render() {
    return (
      <Container>
        <section className="marginBottom50">
          <h1 style={{ fontSize: "50px" }} className="text-center mb-5">
            <span className="reciprocityGreenText">i</span>t's time to say thank you for where we live<span className="reciprocityLightBlueText">.</span>
          </h1>
          <p className='font-thin'>
            Through Reciprocity Trusts, British Columbians can start saying thank you for over one hundred and fifty years of rent-free living by paying a little back each year. These annual payments will go directly to participating Indigenous Nations, who have control over where they accept payments from and what priorities they go towards.
          </p>
        </section>

        <section className="marginBottom50">
          <div className="text-center">
            <Image src="/how-r-works.png" />
          </div>
          <div className="text-center">
            <Image src="/how-r-works-2.png" />
          </div>
        </section>

        <section className="marginBottom100">
          <p className='font-thin'>
            Reciprocity payments collected from homeowners, renters, and business-owners across B.C. are deposited into the relevant Regional Indigenous-owned Reciprocity Trust.
          </p>
          <p className='font-thin'>
            Reciprocity Agreements confirm Indigenous participation in a regional Indigenous-owned Reciprocity Trust and govern how funds are shared amongst Reciprocity Nations.
          </p>
          <p className='font-thin'>
            Reciprocity Nations receive a reliable, ongoing, and independent revenue stream based on allocations agreed upon in the Reciprocity Trust Agreements and decide how to use their allocated funds based on a set of Nation-determined priorities. This revenue stream grows over time as property values increase and more residents and businesses join the movement.
          </p>
        </section>
        <div id="whathappensnext">
          <WhatHappensNext />
        </div>
      </Container>
    )
  }
}
export default withRouter(HowRWorks)

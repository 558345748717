import React from 'react';

import Registration, { RegistrationTypes } from '../Registration/Registration';
import Success from '../Success/Success';
import { default as withRouter } from "../../components/WithRouter/withRouter";

class ImABusinessOwner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "register"
    };
  }

  onSuccess = () => {
    this.setState({
      mode: "success"
    })
  }

  render() {
    return (
      <>
        {this.state.mode !== 'success' &&
          <>
            <h1 className={`marginTop100 marginBottom25 text-center`}>
              <span className="reciprocityGreenText">i</span>'m a business owner<span className="reciprocityLightBlueText">.</span>
            </h1>
            <Registration type={RegistrationTypes.business} image="/reciprocity-business-logo.png" submitText="Get in touch"
              subText={<span>Tell us a bit about the business you'd like to make part of Reciprocity below and we'll get in touch to discuss your Reciprocity Payment for the year. We follow the <a target='_blank' rel='noopener noreferrer' href='https://pledge1percent.org/overview/'>Pledge 1%</a> model, which commits 1% of profits, shares, time and/or product.&nbsp;</span>}
              onSuccess={this.onSuccess}
            />
          </>
        }
        {this.state.mode === 'success' &&
        <Success type='business'></Success>
        }
      </>
    )
  }
}

export default withRouter(ImABusinessOwner);

import React, { Component } from 'react';
import moment from 'moment';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/esm/Container';
import Image from 'react-bootstrap/esm/Image';
import FancyR from '../../components/FancyR/FancyR';

const today = moment();
const currentYear = today.year();

class FAQ extends Component {

  openAccordionByUrl() {
    const tag = window.location.href.split('#faq_')[1];
    return tag;
  }

  render() {
    return (
      <Container className='faq-container'>
        <h1 className="text-center">
          <span className="reciprocityGreenText">f</span>aq<span className="reciprocityLightBlueText">.</span>
        </h1>
        <p className="text-center text-muted marginBottom50">
          Our most frequently asked questions and answers.
        </p>
        <div className="marginBottom100">

          <Accordion defaultActiveKey={this.openAccordionByUrl}>
            <a className="anchor" id='faq_5'></a>
            <Accordion.Item className='faq-card' eventKey="5">
              <Accordion.Header className="pointer">
                <b>
                  Why reciprocity?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="5" className="text-muted" >
                <p className='font-thin'>
                  Reciprocity Trusts was born out of the realisation that residential development and private property ownership remain a net impact on Indigenous wellbeing and that our governments won't touch private property in reconciliation processes. So what happens when the government has already transferred big chunks of Indigenous land (or thousands of little chunks) to private property owners without Indigenous consent?
                </p>
                <p className='font-thin'>
                  It's a colonial mess that needs attention from property owners. We've expanded this thinking to include businesses, renters and just about anyone in Reciprocity
                </p>
                <p className='font-thin'>
                  What we are aiming for is a house-by-house (or business-by-business) 21st-century DIY reboot of the mutual recognition, respect and sharing (that would be reciprocity) talked about in the historic treaties. We think that same spirit and intent should connect us as relative newcomers with the Indigenous governments on whose land we live, play and work. Think of it as territorial recognition made real – with tools and ongoing connections that help people decolonize their own backyards.
                </p>
                <p className='font-thin'>
                  Reciprocity is in no way trying to make up for the past, nor is it nearly substantive enough to achieve decolonization or reconciliation. It's a pathway forward, a way to meaningfully demonstrate gratitude and a small step we can all take in our respective decolonization journeys.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="6">
              <a className="anchor" id='faq_6'></a>
              <Accordion.Header className="pointer" >
                <b>
                  How is Reciprocity different from the City of Victoria’s Reconciliation Contribution Fund?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="6" className="text-muted">
                <p><strong>If you live in the City of Victoria, there are 2 ways to be part of reciprocity...</strong></p>
                <p className='font-thin'>
                  In 2022, with early support from Reciprocity Trusts, the City of Victoria opened its own Reconciliation Contribution Fund to receive voluntary contributions.  If you live in Victoria , you have two ways to be part of reciprocity :
                </p>
                <ol className='font-thin'>
                  <li>
                    You can contribute any amount to the Songhees Nation and Esquimalt Nation through the City of <a href="https://www.victoria.ca/EN/main/city/witness-reconciliation-program/reconciliation-contribution-fund.html" target="_blank" rel="noopener noreferrer">Victoria’s Reconciliation Contribution Fund</a>. Contributions are made separate from tax payments, are not tied to property value, and do not receive tax receipts. Payments flow to Songhees Nation and Esquimalt Nation through the City of Victoria.
                  </li>
                  <div className='my-3'>
                    OR
                  </div>
                  <li>
                    You can contribute a recommended amount through <a href="reciprocitytrusts.ca" target="_blank" rel="noopener noreferrer">reciprocitytrusts.ca</a>, a non-governmental not-for-profit. Recommended amounts are tied to the value of land in the Greater Victoria region, including Victoria, Saanich, and other municipalities on the south island. Payments are managed through a regional  Indigenous-led South Island Indigenous Reciprocity Trust to benefit all beneficiary Nations in the Greater Victoria region, including Songhees Nation and Esquimalt Nation. Contributions are eligible for tax receipts and are reported on annually. If you make the recommended reciprocity payment, your home or business receives an annual certification that is  tracked and moves with your property over time. It is a bit  like a fair trade certificate for homes and businesses, and it helps make recognition of Indigenous lands more visible across the region.
                  </li>
                </ol>
                <p className='font-thin'>
                  We don’t mind how you participate in reciprocity, we just want more people to recognize where they live and say thank you in a meaningful way.
                </p>
                <p className='font-thin'>
                  If you choose to contribute through <a href="reciprocitytrusts.ca">reciprocitytrusts.ca</a>, you are already part of our growing family. If you choose to contribute through the City of Victoria, that’s great too, and to encourage you a bit more, if you’d like recognition through reciprocity, just keep your proof of payment to the CoV fund, and let us know.  We will recognize any CoV reconciliation contributions towards your annual <a href="https://reciprocitytrusts.ca/im-a-homeowner" target="_blank" rel="noopener noreferrer">recommended reciprocity payment</a>.
                </p>
                <p className='font-thin'>
                  On behalf of everyone at Reciprocity and the South Island Indigenous Reciprocity Trust, we thank you for your ongoing support, active participation, and your commitment to starting to decolonize your backyard.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="14">
              <a className="anchor" id='faq_14'></a>
              <Accordion.Header className="pointer">
                <b>
                  Are contributions to the Trust eligible for a tax-receipt?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="14" className="text-muted">
                <p className='font-thin'>
                  Yes! Reciprocity Trusts Society has partnered with the <a href='https://www.smokehousefoundation.org/' target='_blank' rel="noopener noreferrer">Smokehouse Foundation</a>, Canadian Charity # 734475338 RR 0001 to administer Reciprocity Payments, ensure that beneficiary First Nations receive funds in a timely and efficient manner, and that contributing homes and businesses are eligible for tax-deductible receipts.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="8">
              <a className="anchor" id='faq_8'></a>
              <Accordion.Header className="pointer">
                <b>
                  Is Reciprocity Trusts a registered non-profit or a charity?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="8" className="text-muted">
                <p className='font-thin'>
                  Reciprocity Trusts Society is a registered non-profit society in B.C.. In our start-up phase, the Reciprocity Trusts Society is largely supported by in-kind and financial contributions from <a href="https://reciprocityconnects.ca/">Reciprocity Research Inc.</a>, a social enterprise that provides Indigenous governments in Western Canada and beyond with high-quality community-based social and ecological science, impact assessment and restoration that focuses on what matters most to communities.
                </p>
                <p className='font-thin'>
                  Payments are tax deductible because most Indigenous governments in Canada are now recognized as 'Qualified Donees' by CRA. Because the Reciprocity Trusts Society is not a registered charity, we partner with CRA recognized Indigenous Nations in a ‘Fiscal Agency Agreement’ to ensure contributions are tax deductible.  Through the social enterprise model, Reciprocity Research Inc. uses its own revenue to support and grow this quiet Reciprocity revolution, making Indigenous land and language more visible.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="1">
              <a className="anchor" id='faq_1'></a>
              <Accordion.Header className="pointer">
                <b>
                  How do I know which First Nations' land I'm on?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="1" className="text-muted">
                <p className='font-thin'>
                  If you live within one of Reciprocity's Regional Trust Areas, we'll let you know whose traditional territories you live on when you fill in the calculator tool. There are a few great sites that can help you further understand whose traditional territories you live on. Check out <a href="https://native-land.ca/">https://native-land.ca/</a> and <a href="https://www.whose.land/en/">https://www.whose.land/en/</a>.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="2">
              <a className="anchor" id='faq_2'></a>
              <Accordion.Header className="pointer">
                <b>
                  How are reciprocity payments calculated?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="2" className="text-muted">
                <p className='font-thin'>
                  You might be familiar with <a href="https://www.onepercentfortheplanet.org/">1% for the Planet</a> or the <a href="https://pledge1percent.org/overview/">1% Pledge</a>–Reciprocity follows a similar 1% model, which makes getting started approachable and affordable to a diversity of individuals and businesses–everyone has a 1%.
                </p>
                <p className='font-thin'>
                  For homeowners, the total recommended payment is equal to approximately 1% of your property taxes per month (12% of your annual property tax).
                </p>
                <p className='font-thin'>
                  For renters, the recommended payment is equivalent to 1% of your monthly rent.
                </p>
                <p className='font-thin'>
                  For business owners, we follow the 1% Pledge which commits 1% of profits, shares, time and/or product. If you're a business owner, <a href='/get-in-touch'>contact us</a> to find out how you can be involved.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="4">
              <a className="anchor" id='faq_4'></a>
              <Accordion.Header className="pointer">
                <b>
                  When reciprocity payments are made, where will the money go?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="4" className="text-muted">
                <p className='font-thin'>
                  Reciprocity Payments are deposited directly into regional, Indigenous-owned Trusts. From there, your Reciprocity payments will go to participating Indigenous Nations (the Trusts ‘beneficiaries'), who have control over where they accept payments from and what priorities they go towards.
                </p>
                <p className='font-thin'>
                  90% of every Reciprocity payment will go directly to the Indigenous Nations whose lands you live and work on. In the start up phase, 10% of your Reciprocity payment will help cover some of the admin, communications and outreach costs associated with building and growing Reciprocity. As we get more established, we anticipate Reciprocity costs will decrease over time and Indigenous Nations will receive a higher percentage of Reciprocity payments.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="0">
              <a className="anchor" id='faq_0'></a>
              <Accordion.Header className="pointer">
                <b>
                  What is a Reciprocity Trust?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="0" className="text-muted">
                <p className='font-thin'>
                  Reciprocity and First Nations across BC are working together to set up Reciprocity Trusts. The trusts are essentially like bank accounts, and will be governed, or led, by participating Indigenous Nations (trustees). We are focussed on building pilot projects and establishing Trusts in three areas of southern BC:
                </p>
                <ul>
                  <li>
                    Vancouver-Lower Mainland
                  </li>
                  <li>
                    Victoria-South Island
                  </li>
                  <li>
                    Kootenays
                  </li>
                </ul>
                <div className="text-center">
                  <Image src="/how-r-works.png" />
                </div>
                <p className='font-thin'>
                  In addition, we are working to establish the Reciprocity Foundation Trust, which will serve all areas in B.C. that are outside of the Regional Trust areas.
                </p>
                <p className='font-thin'>
                  Each Reciprocity Trust is governed by a group of Trustees appointed from participating Indigenous Nations (one Trustee per participating Nation). The Trustees are responsible for establishing and following the Trust rules, and distributing assets to beneficiaries (participating Indigenous Nations).
                </p>
                <p className='font-thin'>
                  Reciprocity Trusts allow the greatest amount of flexibility and self-determination for participating Indigenous Nations, and also maintain benefits that allow contributions be tax-deductible for the contributors (you!) and tax-free for participating Indigenous Nations.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="7">
              <a className="anchor" id='faq_7'></a>
              <Accordion.Header className="pointer">
                <b>
                  How are Reciprocity Trust terms established?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="7" className="text-muted font-thin">
                <p>
                  The terms of each Trust are established independently in order to meet the specific and unique needs of the Indigenous Nations participating in each Trust. When participating Indigenous Nations appoint a Trustee to the governance board of a Reciprocity Trust, we will work together with all parties to develop a the terms of the Trust and a Reciprocity Agreement, including details such as:
                </p>
                <ul>
                  <li>
                    The areas from which Nations wish, or do not wish, to collect Reciprocity payments from;
                  </li>
                  <li>
                    The three or four most important things the Nations wish to use Reciprocity funds for (e.g. language, land or Nation re-building);
                  </li>
                  <li>
                    How Reciprocity Payments will be shared and distributed amongst participating First Nations in each Trust area. 10% of the funds are used to cover admin and outreach costs, while 90% of the funds going to the beneficiary First Nations;
                  </li>
                  <li>
                    Confirmation that participation is without prejudice to Indigenous rights and title;
                  </li>
                  <li>
                    Tax, receipting and reporting protocols, including tax-receipts provided on behalf of beneficiaries; and
                  </li>
                  <li>
                    A process for settling disagreements within the Trusts
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' id='faq_9' eventKey="9">
              <a className="anchor" id='faq_9'></a>
              <Accordion.Header className="pointer">
                <b>
                  Can I make reciprocity payments for previous years?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="9" className="text-muted">
                <p className='font-thin'>
                  You bet. Maybe you want to make Reciprocity payments for each year you've been in your current home, for your business since its start-up phase, or even for the years you spent growing up in your childhood home. If it's within B.C., we can accommodate that through the calculator tool, or get in touch and we can crunch the numbers with you.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="10">
              <a className="anchor" id='faq_10'></a>
              <Accordion.Header className="pointer">
                <b>
                  I don't live in an established Reciprocity Trust area, can I still participate?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="10" className="text-muted">
                <p className='font-thin'>
                  Reciprocity payments are currently being accepted in the South Island Indigenous Regional Reciprocity Trust area.If you are located outside of what is known today as the ‘Capital Regional District’, fill out the calculator tool or get in touch to let us know where you are based. We’ll keep you informed about where we go next.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="11">
              <a className="anchor" id='faq_11'></a>
              <Accordion.Header className="pointer">
                <b>
                  Is reciprocity just about financial payments?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="11" className="text-muted">
                <p className='font-thin'>
                  That's up to you! We envision making a Reciprocity payment being the first step you take to engage with Reciprocity and the Indigenous Nations whose lands you're on. From there, you become part of the Reciprocity community which connects you — online and in real life– to Reciprocity Nations and your Reciprocity Trust. We'll send you annual updates about what types of initiatives Reciprocity dollars in your Trust area are being used for and connect you with initiatives in your neighbourhood that help recognize and support Indigenous lands and rights.
                </p>
                <p className='font-thin'>
                  If you're interested, we can also set up a consult or a personalised Reciprocity Restoration plan for your property to help you restore the ecological and cultural function of your back (or front, or side) yard.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="12">
              <a className="anchor" id='faq_12'></a>
              <Accordion.Header className="pointer">
                <b>
                  How does Reciprocity use information collected?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="12" className="text-muted">
                <p className='font-thin'>
                  Reciprocity respects your privacy and will take good care of your information. Any personal information you provide when you make a Reciprocity payment or sign up for the Reciprocity newsletter will be stored securely. Your contact information may be shared with participating Indigenous Nations in your area so we can keep you informed on how your Reciprocity Payments are being used and connect you to any relevant events or initiatives happening in your community. You can unsubscribe from receiving Reciprocity related communications at any time.

                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className='faq-card' eventKey="13">
              <a className="anchor" id='faq_13'></a>
              <Accordion.Header className="pointer">
                <b>
                  What happens after homes and businesses join reciprocity?
                </b>
              </Accordion.Header>
              <Accordion.Body eventKey="13" className="text-muted font-thin">
                <p>If you make your recommended Reciprocity payment for {currentYear}, we'll confirm your property is eligible for Reciprocity status (most are). If it is eligible, your payment will be processed and your home will be certified as an <FancyR text='home' /> or <FancyR text='business' /> based on the number of years you have contributed.</p>
                <p>You will also receive:</p>
                <ul>
                  <li><p>A tax deductible receipt that you can claim when you file your tax return.  We'll work with your Reciprocity Trust and Reciprocity Nations in your area to get that to you. We usually send tax receipts out digitally in January or February.</p></li>
                  <li><p>Recognition as an <FancyR text='home' /> or <FancyR text='business' />, including placement on the Reciprocity Web Map (with your permission).</p></li>
                  <li><p>Nifty swag for your front door or yard to recognize your <FancyR text='home' /> or <FancyR text='business' /> status and help you recognize Indigenous rights by making them more visible in your neighbourhood.</p></li>
                  <li><p>Connection with the Reciprocity community &mdash; online, and in real life &mdash; including connections to Reciprocity Nations, your Reciprocity Trust, and invitations to Reciprocity events near you.</p></li>
                  <li><p>An annual report to keep you informed, and let you see where your Reciprocity dollars are going.</p></li>
                  <li><p>Stewardship support to help you restore the ecological and cultural function of your back (or front, or side) yard. This includes stewardship information, phone or email advice, a personalised Reciprocity Restoration plan for your property (if desired), referrals, and maybe even some volunteer assistance to help make it happen.</p></li>
                  <li><p>Info on other things you can do to support and recognize Indigenous lands and rights in your neighbourhood, and maybe even get our 'plus' recognition. This would set your property apart with the <FancyR plus='true' /> symbol.</p></li>
                </ul>
                <p><span className='font-weight-bold'>And REALLY IMPORTANT </span><span role='img' aria-label='smiley'>&#128513;</span> &mdash; If you are ever selling or advertising your home, remember to let others know about your home's <FancyR /> or <FancyR plus='true' /> status &mdash; Reciprocity status stays with your property and adds value when you sell it. When buyers or renters see your property, they'll know they can feel good by choosing a Reciprocity home. It's kind of like a fair trade label for your home.</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    );
  }
}
export default FAQ;

import React from 'react';
import { default as withRouter } from "../../components/WithRouter/withRouter";

import Registration, { RegistrationTypes } from '../Registration/Registration';
import Success from '../Success/Success';

class ImSomethingElse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "register"
    };
  }

  onSuccess = () => {
    this.setState({
      mode: "success"
    })
  }

  render() {
    return (
      <>
        {this.state.mode !== 'success' &&
          <>
            <h1 className={`marginTop100 marginBottom25 text-center`}>
              <span className="reciprocityGreenText">i</span>'m hosting an event<span className="reciprocityLightBlueText">.</span>
            </h1>
            <Registration type={RegistrationTypes.other} image="/reciprocity-other-logo.png" submitText="Get in touch"
              subText="Are you hosting an event, a gathering, or are you simply looking to get more information? Tell us a bit about what you're up to and we'll work with you to make a Reciprocity plan."
              onSuccess={this.onSuccess}
            />
          </>
        }
        {this.state.mode === 'success' &&
          <Success type='other'></Success>
        }
      </>
    )
  }
}

export default withRouter(ImSomethingElse);

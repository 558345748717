import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, OverlayTrigger, Popover } from 'react-bootstrap'
import moment from 'moment';
import FancyR from '../FancyR/FancyR';

const today = moment();
const currentYear = today.year();

class WhatsNextPopover extends React.Component {

    popover = (
        <Popover id="popover-basic" className="p-3 text-center">
        <h3 as="h3">What happens next?</h3>
        <div>
            <p className="mb-1">
                <em>If you make your recommended Reciprocity payment for {currentYear}, we'll confirm your property is eligible for Reciprocity status (most are). If it is eligible, your payment will be processed and your home will be certified as an <FancyR text='home'/> based on the number of years you have contributed.</em>
            </p>
            <Row className="d-flex justify-content-center">
                <Link to='/how-r-works#whathappensnext' target="_blank">
                    <button className="btn btn-link mb-2">More...</button>
                </Link>
            </Row>
        </div>
    </Popover>
    );

    render() {
        return(
            <OverlayTrigger trigger="click" placement="top" overlay={this.popover}>
                    <button className="btn btn-link mb-3"><h3>Where would my dollars go?</h3></button>
            </OverlayTrigger>

        );
    }
}

export default WhatsNextPopover;

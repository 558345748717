import React from 'react';
import { default as withRouter } from "../../components/WithRouter/withRouter";

import './ActsOfReciprocity.css';

class ActsOfReciprocity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      session: null
    };
  }

  render() {
    return (
      <>
        <img
          src="/acts-of-reciprocity-header.svg"
          alt="Acts of Reciprocity"
        >
        </img>
        <div className="container">
          <div className="m-5">
            <h4>
              Everybody has a <strong>role</strong> AND a <strong>responsibility</strong> in decolonization and reconciliation, <em>(especially if you and your family benefit from colonial land policies)</em>.
            </h4>
          </div>
          <div className="mb-4 text-muted font-thin font-weightlight">
            <p>
              Reciprocity is the act of building relationships over time through mutual exchange. Engaging in reciprocity is a way of saying <em>“I know where I live, I recognize Indigenous land rights, and I want to decolonize the culture of home.”</em>
            </p>
            <p>
              Reciprocity payments are about recognizing the benefit and wealth that land creates for our families and returning some of that wealth to the First Nations who have taken care of these lands for millennia. We started Reciprocity because we know we have to go beyond the simple words of land acknowledgements, but we also know that being part of Reciprocity means more than just contributing financially to Reciprocity Trusts.
            </p>
            <p>
              Whether or not you are financially able to make a payment this year, or you have already made a payment and want to do more, <strong>there are many ways to make reciprocity a part of your daily life as a guest on Indigenous lands.</strong>
            </p>
            <p>
              Here’s a list of <strong>30 possible acts of reciprocity</strong> that we hope will help fan the flames of your own creative fire.
            </p>
          </div>
          <div className="mb-4">
            <img
              src="/acts-of-reciprocity-1.png"
              alt="Acts of Reciprocity 1-10"
              className="w-100 img-fluid"
            ></img>
          </div>
          <div className="mb-5 text-muted font-thin font-weightlight">
            <ol>
              <li>
                <strong>Make a Reciprocity Payment & tell your friends allllllll about it :)</strong> We are a non-profit organization that enables homes and small businesses in the Capital Regional District (other regions coming online in future!) to ‘pay rent’ to local First Nations by making voluntary Reciprocity payments, collected into an Indigenous-governed Trust.
              </li>
              <li>
                <strong>Visit us at a Reciprocity event!</strong> Take and share a picture of the Reciprocity tent at a local community event. Check out our “Events” highlight reel on Instagram.
              </li>
              <li>
                <strong>Seek out Indigenous art</strong>, theory, books, podcasts, articles, poetry, music, social media accounts, talks and events that show you new ways of understanding Indigenous lives and experiences. Be open to learning and challenging your beliefs.
              </li>
              <li>
                <strong>“Show up” for local First Nations communities:</strong> attend public-invite events and ceremonies, vocally support their decision-making authority in regards to governance of their territories, know and teach others whose land you’re on, support and amplify youth voices, attend markets and celebrate their wins (eg. land back).
              </li>
              <li>
                <strong>Talk to people you are close to about colonial privilege</strong> and how it impacts your lives. Try to hold compassion and curiosity for the ways that someone else’s experience differs from yours, maybe due to ethnicity, gender, class or age.
              </li>
              <li>
                <strong>Explore what it means to be “Canadian”.</strong> How can we reframe this identity to imply a certain set of responsibilities to First Nations and the land?
              </li>
              <li>
                <strong>Try writing or practicing self-location</strong> where you explain your heritage, where you’ve lived, what those personal and ancestral histories have given you, and how you intend to use these gifts for a greater good. Self-locations are a casual, though sometimes formal, part of engaging with others, especially marginalized folks, and especially when speaking or writing on issues of oppression (such as colonization).
              </li>
              <li>
                <strong>Support Indigenous economies.</strong> First Nations have practiced, sustained and adapted their own economies for millennia, though colonization has impacted Indigenous economic independence. Supporting Indigenous economies can look like: buying goods and services from Indigenous artisans, businesses and service/experience providers; supporting Indigenous certification processes by only shopping at businesses that are certified to give back (e.g Tribal Park Allies in Tla-o-qui-aht territory, Reciprocity Businesses); and supporting Indigenous rights to subsistence and commercial fishing and harvesting.
              </li>
              <li>
                <strong>Help out on the ground with ecological restoration efforts</strong> such as invasive plant removals, cleaning up trash along rivers and coasts, native species planting and protection (with Indigenous guidance), or helping restore agricultural lands to wetlands or open grasslands. Find your place in sustaining the earth for generations to come. Learn more: <a href="https://reciprocitytrusts.ca/backyard-restoration" target="_blank" rel="noopener noreferrer">https://reciprocitytrusts.ca/backyard-restoration </a>
              </li>
              <li>
                <strong>Give genuine land acknowledgements</strong> at events to demonstrate good practice and meaningful recognition, and offer ways for the audience to give back to the community (such as making Reciprocity payments).
              </li>
            </ol>
          </div>
          <div className="mb-4">
            <img
              src="/acts-of-reciprocity-2.png"
              alt="Acts of Reciprocity 11-20"
              className="w-100 img-fluid"
            ></img>
          </div>
          <div className="mb-5 text-muted font-thin font-weightlight">
            <ol start="11">
              <li>
                <strong>Support Indigenous language revitalization.</strong> Use Indigenous place names rather than colonial ones and learn the history behind them; learn basic important local words and phrases; and advocate for language revitalization programs.
              </li>
              <li>
                <strong>Donate funds or requested supplies to land defenders</strong> (e.g., <a href="https://unistoten.camp/support-us/donate/" target="_blank" rel="noopener noreferrer">Unist’ot’en Camp</a>, <a href="http://www.tinyhousewarriors.com/" target="_blank" rel="noopener noreferrer">Tiny House Warriors</a>, <a href="https://freegrassy.net/please-donate-2021/" target="_blank" rel="noopener noreferrer">Grassy Narrows</a>, <a href="https://www.gofundme.com/f/legal-fund-1492-land-back-lane" target="_blank" rel="noopener noreferrer">1492 Land Back Lane</a>), after doing research into where the money goes and seeking out the voices of youth and those in marginal positions within the movement (because lateral violence can still exist within movements).
              </li>
              <li>
                <strong>Learn about an Indigenous-led movement.</strong> From Red Power to Idle No More, there is a rich history of Indigenous resistance and leadership in social movements to learn about.
              </li>
              <li>
                <strong>Recognize and celebrate Indigenous existence, diversity, resistance and excellence,</strong> and promote a strengths-based narrative of Indigenous people rather than a victim-based story that incentivizes white saviourism.
              </li>
              <li>
                <strong>Learn about local native plant species</strong> and their uses. Are they still readily available in Indigenous territories?
              </li>
              <li>
                <strong>Participate in Indigenous days of remembrance and celebration</strong>, and carry this recognition throughout the rest of the year as well.
              </li>
              <li>
                <strong>Diversify your news sources to include Indigenous voices</strong> and perspectives. Be conscious of the media you and the people close to you typically consume and be critical of any harmful images or messaging about Indigenous Peoples and Indigenous sovereignty.
              </li>
              <li>
                <strong>Reflect on how the places you have lived in your life have shaped you.</strong> How do those places recognize, acknowledge and honour their history and contemporary Indigenous influence?
              </li>
              <li>
                <strong>Familiarize yourself with the state of Indigenous land claims where you live/work/play:</strong> is there a treaty? (if so, read it). Is there a community that has all the resources and space that it needs? Is there a re-occupation effort? Is there a corresponding Aboriginal right or title case in the courts? Where are the sacred/culturally important places you shouldn’t visit?
              </li>
              <li>
                <strong>Educate yourself on residential schools</strong>, but remain aware that they are not the root cause of all problems Indigenous Peoples face today. They were <em>one single tool</em> within the larger toolbox of the settler colonial project. They had devastating intergenerational effects, but focusing public attention on this as the sole cause of Indigenous struggle is a whole other settler colonial tactic of deterrence and control.
              </li>
            </ol>
          </div>
          <div className="mb-4">
            <img
              src="/acts-of-reciprocity-3.png"
              alt="Acts of Reciprocity 21-30"
              className="w-100 img-fluid"
            ></img>
          </div>
          <div className="mb-5 text-muted font-thin font-weightlight">
            <ol start="21">
              <li>
                <strong>Promote Indigenous influentials, creatives and role models.</strong>
              </li>
              <li>
                <strong>Make reciprocity an inter-generational family project.</strong> Share your learning with your parents, aunties, uncles, cousins and kids.
              </li>
              <li>
                <strong>Engage in mutual aid.</strong> Mutual aid is a way of taking care of each other through the voluntary, collaborative exchange of resources and services for common benefit. Examples include providing basic resources like food, clothing, showers, menstrual supplies or medicine, as well as services like breakfast programs, education or community outreach.
              </li>
              <li>
                <strong>Practice cultural humility.</strong> Cultural humility is the self-reflective practice of recognizing what you don’t know about others and other cultures, histories and lived experiences. The goal is to be humble and build respectful relationships with people that are different from you, and to take on this (un)learning labour yourself rather than ask for guidance from others.
              </li>
              <li>
                <strong>Read baseline documents</strong> relating to either the protection or limitation of Indigenous rights in order to understand their impact in shaping Indigenous lives in “Canada”. (Eg. the United Nations Declaration on the Rights of Indigenous Peoples, the Truth and Reconciliation Commission’s Final Report, the Missing and Murdered Indigenous Women and Girls Final Report, the In Plain Sight Report on Racism in Healthcare, the Royal Commission on Aboriginal Peoples, Pierre Trudeau’s White Paper on Indian Policy, the Indian Act, the Eyeford Report, and Yellowhead Institute papers).
              </li>
              <li>
                <strong>Volunteer at an Indigenous event or non-profit.</strong>
              </li>
              <li>
                <strong>Wear a <a href="https://moosehidecampaign.ca/" target="_blank" rel="noopener noreferrer">Moose Hide campaign</a> pin</strong> at work, school, or in public to bring attention to the issue of violence against Indigenous women and children. If someone asks about it, thank them for asking and share with them what the pin is for.
              </li>
              <li>
                <strong>Learn the history of places where you tend to travel</strong>, vacation or camp – is it on unceded Indigenous territory?
              </li>
              <li>
                <strong>Amplify the voices and experiences of Indigenous youth.</strong>
              </li>
              <li>
                <strong>Build in your own reflection and renewal processes</strong> for all of these acts of reciprocity (eg. journaling at the end of every season).
              </li>
            </ol>
          </div>
          <div className='text-muted font-thin font-weightlight'>
            <h1 className="text-center mb-5">
              (<span className="reciprocityGreenText">Un</span>)Learning Content<span className="reciprocityLightBlueText">.</span>
            </h1>
            <h3 className="mb-4 reciprocityLightBlueText">
              <strong>Articles/Reports</strong>
            </h3>
            <ul className='mb-5'>
              <li>
                <a href='https://jps.library.utoronto.ca/index.php/des/article/view/18630' target="_blank" rel="noopener noreferrer">Decolonization is Not a Metaphor</a> | Eve Tuck, K. Wayne Yang
              </li>
              <li>
                <a href='https://web.uvic.ca/stolo/pdf/Complicated%20Pathways.pdf' target="_blank" rel="noopener noreferrer">Complicated pathways: settler Canadians learning to re/frame themselves and their relationships with Indigenous peoples</a> | Lynne Davis, et al.
              </li>
              <li>
                <a href='https://www.tandfonline.com/doi/full/10.1080/14623520601056240' target="_blank" rel="noopener noreferrer">Settler Colonialism and the Elimination of the Native</a> | Patrick Wolfe
              </li>
              <li>
                <a href='https://www.tandfonline.com/doi/full/10.1080/14623520601056240' target="_blank" rel="noopener noreferrer">Decolonizing Feminism: Challenging Connections between Settler Colonialism and Heteropatriarchy</a> | Maile Arvin, Eve Tuck, Angie Morrill
              </li>
              <li>
                <a href='http://gutsmagazine.ca/indigenous-governance-is-gay/' target="_blank" rel="noopener noreferrer">Indigenous Governance is Gay</a> | Emily Riddle
              </li>
              <li>
                <a href='https://www.artseverywhere.ca/body-remembers-world-broke-open/' target="_blank" rel="noopener noreferrer">The Body Remembers When the World Broke Open</a> | Billy-Ray Belcourt
              </li>
              <li>
                <a href='http://users/dorothyhodgins/Downloads/23505-Article%20Text-58184-1-10-20141201.pdf' target="_blank" rel="noopener noreferrer">Pacification and Indigenous Struggles in Canada</a> | Tia Dafnos
              </li>
              <li>
                <a href='https://yellowheadinstitute.org/resources/weaponizing-injunctions-how-canada-criminalizes-indigenous-land-defense/' target="_blank" rel="noopener noreferrer">How Canada Criminalizes Indigenous Land Defense</a> | Yellowhead Institute
              </li>
              <li>
                <a href='https://yorkspace.library.yorku.ca/xmlui/bitstream/handle/10315/34817/MESMP02421.pdf?sequence=2&isAllowed=y' target="_blank" rel="noopener noreferrer">Cacophonous Settler Grounded Normativity: Interrelationality with the More-Than-Human World as a Path for Decolonial Transformation</a> | Benjamin Kapron
              </li>
              <li>
                <a href='https://nationalseedproject.org/Key-SEED-Texts/white-privilege-unpacking-the-invisible-knapsack' target="_blank" rel="noopener noreferrer">White Privilege: Unpacking the Invisible Knapsack</a> |  Peggy Mcclintock
              </li>
              <li>
                <a href='https://www.beaconbroadside.com/broadside/2018/11/unpacking-the-invisible-knapsack-of-settler-privilege.html' target="_blank" rel="noopener noreferrer">Settler Colonial Knapsack</a> | Dina Gilio-Whitaker
              </li>
            </ul>
            <h3 className="mb-4 reciprocityLightBlueText">
              <strong>Books</strong>
            </h3>
            <p className="mb-3">
              Listed in order of basic groundwork to complex interconnection. Many more on this <a href='https://docs.google.com/document/d/1Hrxir_IMWU48ye1_WuIEF4DvxQ1R7HOEY1kiIaSk9Tk/mobilebasic' target='_blank' rel='noopener noreferrer'>Decolonization Reading List</a>, but these together provide a solid foundation.
            </p>
            <ul className='mb-5'>
              <li>The Inconvenient Indian | Thomas King (Cherokee)</li>
              <li>500 Years of Indigenous Resistance | Gord Hill (Kwakwaka'wakw)</li>
              <li>Indigenous Writes | Chelsea Vowel (Métis) </li>
              <li>Unsettling Canada | Arthur Manuel (Secwepemc) </li>
              <li>The Reconciliation Manifesto: Recovering the Land, Rebuilding the Economy | Arthur Manuel </li>
              <li>Memory Serves | Lee Maracle (Sto:lo) </li>
              <li>Lighting the Eighth Fire | Leanne Betasamosake Simpson (Anishinaabe) </li>
              <li>Canadian Law and Indigenous Self-Determination | Gordon Christie (Inupiat/Inuvialuit) </li>
              <li>Canada’s Indigenous Constitution | John Borrows (Anishinaabe)</li>
              <li>Red Skin, White Masks: Rejecting the Colonial Politics of Recognition | Glen Coulthard (Yellowknives Dene) </li>
            </ul>
            <h3 className="mb-4 reciprocityLightBlueText">
              <strong>Webpages</strong>
            </h3>
            <ul className='mb-5'>
              <li>
                <a href='https://library.northshore.edu/c.php?g=1056535&p=7676306' target='_blank' rel='noopener noreferrer'>What does white privilege and anti-racism look like?</a> | Northshore Edu
              </li>
              <li>
                <a href='https://www.firstpeopleslaw.com/aboriginal-law-report' target='_blank' rel='noopener noreferrer'>Indigenous legal updates and analysis</a> | First Peoples Law
              </li>
              <li>
                <a href='https://www.academica.ca/indigenoustopten' target='_blank' rel='noopener noreferrer'>Current Indigenous Top Ten</a> | Academica Group
              </li>
              <li>
                <a href='https://theconversation.com/settlers-with-opinions-83338' target='_blank' rel='noopener noreferrer'>Settlers with Opinions</a> | The Conversation
              </li>
              <li>
                <a href='https://www.indigenousbar.ca/pdf/undrip_handbook.pdf' target='_blank' rel='noopener noreferrer'>Handbook for Implementing UNDRIP</a> | Indigenous Bar Association
              </li>
            </ul>
            <h3 className="mb-4 reciprocityLightBlueText">
              <strong>Podcasts</strong>
            </h3>
            <ul className='mb-5'>
              <li>
                <a href='https://www.google.com/search?client=firefox-b-e&q=mediaindigena' target='_blank' rel='noopener noreferrer'>Media Indigena</a>
                <ul>
                  <li>
                    Host Rick Harp (Cree) and recurring guest speakers provide a platform for complex Indigenous topics to be explored in depth.
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://www.firstpeopleslaw.com/public-education/first-peoples-lawcast' target='_blank' rel='noopener noreferrer'>First Peoples Lawcast</a>
                <ul>
                  <li>
                    Podcast featuring conversations on the defence of Indigenous rights and legal orders.
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://thetraumainformedlawyer.simplecast.com/' target='_blank' rel='noopener noreferrer'>The Trauma-Informed Lawyer </a>
                <ul>
                  <li>
                    This podcast will serve as your educational resource on trauma-informed lawyering. Through inspiring interviews and thoughtful commentary, Myrna McCallum will shine a light on a critical competency you did not get any instruction on in law school.
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://www.allmyrelationspodcast.com/' target='_blank' rel='noopener noreferrer'>All My Relations</a>
                <ul>
                  <li>
                    All My Relations is a team of folks who care about representations, and how Native peoples are represented in mainstream media.
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://www.indianandcowboy.com/red-man-laughing-podcast' target='_blank' rel='noopener noreferrer'>Red Man Laughing</a>
                <ul>
                  <li>
                    Ryan McMahon’s (Anishinaabe/Métis) storytelling comedy style is fast-paced, loose & irreverent as he explores the good, the bad & the ugly between Indian Country & the mainstream.
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://tchadasleo.ca/hosted/' target='_blank' rel='noopener noreferrer'>Our Native Land</a>
                <ul>
                  <li>
                    Tchadas Leo is an award-winning Indigenous digital media producer. Tchadas brings indigenous content to mainstream media through projects like Our Native Land, commissioned by CHEK.
                  </li>
                </ul>
              </li>
            </ul>
            <h3 className="mb-4 reciprocityLightBlueText">
              <strong>Films/Documentaries:</strong>
            </h3>
            <ul className='mb-5'>
              <li>
                <a href='https://www.nfb.ca/subjects/indigenous-peoples-in-canada-first-nations-and-metis/' target='_blank' rel='noopener noreferrer'>Films by and on Indigenous Peoples in Canada</a> | NFB
              </li>
            </ul>
          </div>
          <div className='mb-5'>
            <h4>
              Do you have resources you’d like to share & add to our list? Please send them our way! <a href='mailto:hello@reciprocityconnects.ca' target='_blank' rel='noopener noreferrer'>hello@reciprocityconnects.ca</a>.
            </h4>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ActsOfReciprocity);;
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import Home from './pages/Home/Home'
// import Auth from './pages/Auth/Auth'
// import Dashboard from './pages/Dashboard/Dashboard'
import OurTeam from './pages/OurTeam/OurTeam'
import GetInTouch from './pages/GetInTouch/GetInTouch'
import Newsletter from './pages/Newsletter/Newsletter'
import FAQ from './pages/FAQ/FAQ'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import { deleteSession } from './utils'
import ReciprocityMap from './pages/ReciprocityMap/ReciprocityMap'
import ReciprocityNations from './pages/ReciprocityNations/ReciprocityNations'
import HowRWorks from './pages/HowRWorks/HowRWorks'
import WhatIsReciprocity from './pages/WhatIsReciprocity/WhatIsReciprocity'
import ImSomethingElse from './pages/ImSomethingElse/ImSomethingElse'
import ImABusinessOwner from './pages/ImABusinessOwner/ImABusinessOwner'
import ImARenter from './pages/ImARenter/ImARenter'
import ImAHomeowner from './pages/ImAHomeowner/ImAHomeowner'
import ImIndigenous from './pages/ImIndigenous/ImIndigenous'
import BackyardRestoration from './pages/BackyardRestoration/BackyardRestoration'
import ActsOfReciprocity from './pages/ActsOfReciprocity/ActsOfReciprocity'
import styles from './App.module.css'

export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    deleteSession();
  }

  render() {
    return (
      <div style={{ display: 'flex', 'minHeight': '100vh', 'flexDirection': 'column' }}>
        <Header />
        <Router >
          <ScrollToTop >
            <div style={{ flex: '1', display: 'flex', flexDirection: 'column'}} className={`${styles.body}`}>
              <Routes>
                {/* <Route path='/login'>
                  <Auth />
                </Route> */}

                <Route path='/our-team' element={<OurTeam />}>
                </Route>

                <Route path='/newsletter' element={<Newsletter />}>
                </Route>

                <Route path='/faq'  element={<FAQ />}>
                </Route>

                <Route path='/get-in-touch' element={<GetInTouch />}>
                </Route>

                <Route path='/reciprocity-map' element={<ReciprocityMap />}>
                </Route>

                <Route path='/reciprocity-nations' element={<ReciprocityNations />}>
                </Route>

                <Route path='/how-r-works' element={<HowRWorks />}>
                </Route>

                <Route path='/what-is-reciprocity' element={<WhatIsReciprocity />}>
                </Route>

                <Route path='/backyard-restoration' element={<BackyardRestoration />}>
                </Route>

                <Route path='/acts-of-reciprocity' element={<ActsOfReciprocity />}>
                </Route>

                <Route path='/im-something-else' element={<ImSomethingElse />}>
                </Route>

                <Route path='/im-indigenous' element={<ImIndigenous />}>
                </Route>

                <Route path='/im-a-business-owner' element={<ImABusinessOwner />}>
                </Route>

                <Route path='/im-a-renter' element={<ImARenter />}>
                </Route>

                <Route path='/im-a-homeowner' element={<ImAHomeowner />}>
                </Route>

                <Route path='/thankyou' element={<Home thankyou />}>
                </Route>

                <Route path='/' element={<Home />}>
                </Route>
              </Routes>
            </div>
          </ScrollToTop>
        </Router>
        <Footer />
      </div>
    )
  }
}

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
// const PrivateRoute = ({ component, ...options }) => {

//   const session = getSession();

//   const finalComponent = session ? component : Home
//   return <Route {...options} component={finalComponent}/>
// }

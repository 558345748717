import React from 'react';

import moment from 'moment';
import FancyR from '../FancyR/FancyR';

const today = moment();
const currentYear = today.year();

class WhatHappensNext extends React.Component {

  // scroll to id when pages are loaded dynamically - typical of React
  componentDidMount() {
    const url = window.location.href;
    const idIndex = url.indexOf('#');
    if (idIndex > -1) {
      const id = url.slice(idIndex + 1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  render() {
    return (
      <div className="marginBottom100" id="whatHappensNext">
        <h1 className="reciprocityPurpleText marginBottom50 text-center">what happens next?</h1>
        <div>
          <p className='font-thin'>If you make your recommended Reciprocity payment for {currentYear}, we'll confirm your property is eligible for Reciprocity status (most are). If it is eligible, your payment will be processed and your home, business, or event will be certified as an <FancyR text="home" />, <FancyR text="business" />, or <FancyR text="event" /> based on the number of years you have contributed.</p>
          <p className='font-thin'>You will also receive:</p>
          <ul>
            <li> <p className='font-thin'>A tax deductible receipt that you can claim when you file your tax return.  We'll work with your Reciprocity Trust and Reciprocity Nations in your area to get that to you.</p></li>
            <li> <p className='font-thin'>Recognition as an <FancyR text="home" />, <FancyR text="business" />, or <FancyR text="event" />, including placement on the Reciprocity Web Map (with your permission).</p></li>
            <li> <p className='font-thin'>Nifty swag for your front door, yard or event to recognize your <FancyR text="home" />, <FancyR text="business" />, or <FancyR text="event" /> status and help you recognize Indigenous rights by making them more visible in your neighbourhood.</p></li>
            <li> <p className='font-thin'>Connection with the Reciprocity community &mdash; online, and in real life &mdash; including connections to Reciprocity Nations, your Reciprocity Trust, and invitations to Reciprocity events near you.</p></li>
            <li> <p className='font-thin'>An annual report to keep you informed and to let you see where your Reciprocity dollars are going.</p></li>
            <li> <p className='font-thin'>Stewardship support to help you restore the ecological and cultural function of your back (or front, or side) yard. This includes stewardship information, phone or email advice, a personalised Reciprocity Restoration plan for your property (if desired), referrals, and maybe even some volunteer assistance to help make it happen.</p></li>
            <li> <p className='font-thin'>Info on other things you can do to support and recognize Indigenous lands and rights in your neighbourhood, and maybe even get our 'plus' recognition. This would set your property apart with the  <FancyR plus='true' /> symbol
            </p></li>
          </ul>
          <p><span className='font-weight-bold'>And REALLY IMPORTANT </span><span role='img' aria-label='smiley'>&#128513;</span> &mdash; <span className='font-thin'>If you are ever selling or advertising your home, remember to let others know about your home's <FancyR /> or <FancyR plus='true' /> status &mdash; reciprocity status stays with your property and adds value when you sell it. When buyers or renters see your property, they'll know they can feel good by choosing a Reciprocity home. It's kind of like a fair trade label for your home.</span></p>
        </div>
      </div>
    )
  }
}
export default WhatHappensNext;
